import React from "react";
import Alert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";

const snackbar = (props) => (
    <Snackbar open={props.open} onClose={props.closed} style={props.style} anchorOrigin={{vertical: props.vertical ? props.vertical : 'top', horizontal: props.horizontal ? props.horizontal : 'right'}} autoHideDuration={5000}>
        <Alert onClose={props.closed} severity={props.severity}>
            {props.children}
        </Alert>
    </Snackbar>
);

export default snackbar;
