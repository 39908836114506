import React, {Component} from "react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import instance from "../../../axios-spc";
import Button from '../../../components/UI/Button/Button'
import classes from './Device.css'
import Box from "@mui/material/Box";
import QRCode from "qrcode.react";

class Device extends Component {

    state = {
        device: [],
        loginCode: null,
        deviceInfo: null,
        reclamoUno: null,
        reclamoDue: null,
        codiceFiscale: null,
        idCliente: null,
        numTimes: null,
        openQrCode: false,
        ricevutaPresente: true,
        deviceAnnullato: false
    };

    componentDidMount() {
        // chiamata a picea
        var postData = null;
        var url = null;
        if (localStorage.getItem("role") == 2) {
            url = '/getDeviceMaster';
            postData = {
                id_color: localStorage.getItem("idAffiliato") == 0 ? null : localStorage.getItem("idAffiliato"),
                id_negozio: null,
                serial_number: this.props.id,
                num_times: this.props.serial
            }
        }
        else {
            url = '/getDevice';
            postData = {
                id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
                serial_number: this.props.id,
                num_times: this.props.serial
            }
        }

        instance.post(url, postData).then(resp => {
            let result = [];
            // for (let i in resp.data[0]) {
            //     result.push([(<strong> {i.replace("_", " ") + ": "} </strong>), resp.data[0][i]])
            // }
            result.push([(<strong> {"Serial Number: "} </strong>), resp.data[0]['serial_number']]);
            result.push([(<strong> {"Imei: "} </strong>), resp.data[0]['imei']]);
            result.push([(<strong> {"Brand: "} </strong>), resp.data[0]['brand_name']]);
            result.push([(<strong> {"Modello: "} </strong>), resp.data[0]['model_name']]);
            result.push([(<strong> {"Valore Acquisto: "} </strong>), resp.data[0]['valore_acquisto']]);
            result.push([(<strong> {"Data Acquisto: "} </strong>), new Date(resp.data[0]['data_acquisto']).toLocaleDateString()]);
            result.push([(<strong> {"Stato Device: "} </strong>), resp.data[0]['stato_device']]);
            result.push([(<strong> {"Grado Estetico: "} </strong>), resp.data[0]['grado_estetico']]);
            result.push([(<strong> {"Coupon: "} </strong>), resp.data[0]['coupon_serial_number']]);
            result.push([(<strong> {"Magazzino Attuale: "} </strong>), resp.data[0]['magazzino_attuale']]);
            result.push([(<strong> {"Cliente: "} </strong>), resp.data[0]['nome_cliente']]);
            result.push([(<strong> {"Indirizzo Cliente: "} </strong>), resp.data[0]['indirizzo_cliente']]);
            result.push([(<strong> {"Telefono Cliente: "} </strong>), resp.data[0]['telefono_cliente']]);
            result.push([(<strong> {"Mail Cliente: "} </strong>), resp.data[0]['email_cliente']]);

            if (resp.data[0]['imei_acquisto'] != null) {
                // result.push([(<strong> {"Supervalutazione: "} </strong>), resp.data[0]['serial_number']]);
                result.push([(<strong> {"Imei Supervalutazione: "} </strong>), resp.data[0]['imei_acquisto']]);
                result.push([(<strong> {"SKU Supervalutazione: "} </strong>), resp.data[0]['sku']]);
            }

            let ricevutaPresente = resp.data[0]['documento'] != null;
            let deviceAnnullato = resp.data[0]['id_stato_device'] == 14;
            console.log(ricevutaPresente);
            this.setState({device: result, loginCode: resp.data[0]['serial_number'], codiceFiscale: resp.data[0]['codice_fiscale'], idCliente: resp.data[0]['id_cliente'], numTimes: resp.data[0]['num_times'], ricevutaPresente: ricevutaPresente, deviceAnnullato: deviceAnnullato});
        });
    }

    openTestResult = () => {
        this.setState({loading: true})
        let postData = {
            login_code: this.state.loginCode
        };
        instance.post('/getPhoneCheckDeviceInfo', postData)
            .then(response => {
                if (response.data.data != null) {
                    this.setState({deviceInfo: response.data.data});
                } else {
                    this.setState({loading: false, deviceInfoError: 'Nessun report trovato con il login code utilizzato'});
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false, deviceInfo: null});
            });
    };


    render() {
        let image1 = null;
        let image2 = null;
        let device = null;
        if (this.state.device.length > 0) {
            device = this.state.device.map((item, key) => (
                <Auxiliary><div className={classes.ElementoTabella}><span>{item}</span></div></Auxiliary>
            ));
        }
        const openRicevuta = () => {
            window.open("https://collaudo.smartphonecash.it/stampaRicevuta?cod=" + this.state.device[0][1] + "&num=" + this.state.numTimes);
        };
        const openCoupon = () => {
            window.open("https://collaudo.smartphonecash.it/stampaCoupon?sn=" + this.state.device[8][1]);
        };

        const openQrCodeLink = () => {
            this.setState({openQrCode: true})
        };

        let qrCode = (<div>&nbsp;</div>);
        if (this.state.openQrCode) {
            qrCode = (
                <div style={{marginTop: '5%'}}>
                    <p>{"https://collaudo.smartphonecash.it/takePhoto?cf=" + this.state.codiceFiscale + "&device=" + this.state.loginCode + "&negozio=" + localStorage.getItem("idNegozio") + "&idc=" + this.state.idCliente + "&nt=" + this.state.numTimes}</p>
                    <QRCode
                        value={"https://collaudo.smartphonecash.it/takePhoto?cf=" + this.state.codiceFiscale + "&device=" + this.state.loginCode + "&negozio=" + localStorage.getItem("idNegozio") + "&idc=" + this.state.idCliente + "&nt=" + this.state.numTimes}/>
                    <p>Inquadra il QR Code dal cellulare per scattare le foto al documento</p>
                </div>
            );
        }


        let passedTest = [];
        let failedTest = [];
        let batteryPercentage = null;
        let batteryPercentageFormatted = null;
        let passedTestFormatted = null;
        let failedTestFormatted = null;

        let deviceInfoForm = ( <div style={{display: 'inline-block', width: '90%', columns: 2, marginTop: '5%'}}>
            {failedTestFormatted}
            {batteryPercentageFormatted}
            {passedTestFormatted}
        </div>);


        if (this.state.deviceInfo != null) {
            passedTest = Object.keys(this.state.deviceInfo.passed);
            failedTest = Object.keys(this.state.deviceInfo.failed);
            // batteryPercentage = this.state.deviceInfo.BatteryResults.batteryHealthPercentage.replaceAll(' %', '');
            // batteryPercentageFormatted = <Auxiliary><div className={batteryPercentage > 80 ? classes.PassedTest : classes.FailedTest}><span>{"Battery Health " + batteryPercentage + "%"}</span></div></Auxiliary>;
            passedTestFormatted = passedTest.map((item, key) => (
                <Auxiliary><div className={classes.PassedTest}><span>{item}</span></div></Auxiliary>
            ));
            failedTestFormatted = failedTest.map((item, key) => (
                <Auxiliary><div className={classes.FailedTest}><span>{item}</span></div></Auxiliary>
            ));

            deviceInfoForm = (
                <div style={{display: 'inline-block', width: '90%', columns: 2}}>
                    {failedTestFormatted}
                    {batteryPercentageFormatted}
                    {passedTestFormatted}
                </div>
            )
        }



        return (
            <Auxiliary>
                <div style={{columns: 2}}>
                    {device}
                </div>
                <br/>
                <Box className={classes.FiltriBox}>
                    <div style={{columns: 2}}>
                        <div><Button clicked={this.openTestResult}>Visualizza test effettuati</Button></div>
                        <div><Button disabled={this.state.ricevutaPresente || this.state.deviceAnnullato} clicked={() => openQrCodeLink()}>Acquisisci documenti cliente</Button></div>
                    </div>
                </Box>
                <div style={{columns: 2}} >
                    {deviceInfoForm}
                    {qrCode}
                </div>
                <br/>
                <Box className={classes.FiltriBox}>
                    <div style={{columns: 2}}>
                        <div>
                            {!this.state.ricevutaPresente ? "Acquisire i documenti del cliente per procedere con la stampa della ricevuta" : null}
                            <Button disabled={!this.state.ricevutaPresente || this.state.deviceAnnullato} clicked={() => openRicevuta()}>Visualizza ricevuta completa</Button>
                        </div>
                        <div><Button clicked={() => openCoupon()} disabled={this.state.deviceAnnullato}>Visualizza Coupon</Button></div>
                    </div>
                </Box>

                <div style={{columns: 2}}>
                    {image1}
                    {image2}
                </div>
            </Auxiliary>
        );
    }

}

export default Device
