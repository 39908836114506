import React, {Component} from "react";
import instance from "../../axios-spc";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import classes from "./PublicPriceCheck.css";
import Box from "@mui/material/Box";
import Button from "../../components/UI/Button/Button"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import Divider from '@mui/material/Divider';
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import ICONS from "../../store/icons";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import IconButton from '@mui/material/IconButton';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import queryString from "query-string";



class PublicPriceCheck extends Component {

    state = {
        brands: [],
        modelli: [],
        memoria: [],
        priceData: [],
        idBrandSelezionato: null,
        idModelSelezionato: null,
        idMemoriaSelezionato: null,
        loading: false,
        isRicercaPrezzo: false,
        prezzoCompleto: 0,
        userLongitude: null,
        userLatitude: null,
        showMap: false,
        negozi: [],
        storeToken: null

    };

    componentDidMount() {
        this.getBrands();
        let params = queryString.parse(this.props.location.search);
        let storeToken = params.st;
        this.setState({storeToken: storeToken});
    }



    getBrands = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
        };
        instance.post('/getPriceCheckBrands', postData).then(resp => {
            console.log(resp);
            let result = resp.data.map( el => ({key: el.id_brand, value: el.id_brand, label: el.brand_name}) );
            this.setState({brands: result})
        });
    };

    getModels = (idBrand) => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_brand: idBrand
        };
        instance.post('/getPriceCheckModels', postData).then(resp => {
            let result = resp.data.map( el => ({key: el.id_model_name, value: el.id_model_name, label: el.model_name}) )
            this.setState({modelli: result})
        });
    };

    getMemory = (idModel) => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_model_name: idModel
        };
        instance.post('/getPriceModelsMemory', postData).then(resp => {
            let result = resp.data.map( el => ({key: el.id_memory, value: el.id_memory, label: el.memory}) )
            this.setState({memoria: result})
        });
    };


    onBrandsChange = (event, values) => {
        if (values) this.setState({idBrandSelezionato: values.value, supervalutazioneScelta: null, gradoEsteticoScelto: null, priceData: null}, this.getModels( values.key)); else this.setState({modelli: []});
    };
    onModelsChange = (event, values) => {
        if (values) this.setState({idModelSelezionato: values.value, supervalutazioneScelta: null, gradoEsteticoScelto: null, priceData: null}, this.getMemory( values.value))
    };
    onMemoryChange = (event, values) => {
        if (values) this.setState({idMemoriaSelezionato: values.value, supervalutazioneScelta: null, gradoEsteticoScelto: null, priceData: null})
    };

    selezionaSupervalutazione = (data) => {
        console.log(data);
        let tot = this.state.prezzoCompleto - this.state.valoreSupervalutazione + data.supervalutazione;
        this.setState({prezzoCompleto: tot, supervalutazioneScelta: data.id_campagna, valoreSupervalutazione: data.supervalutazione, rowSelection: [], rowSelectionSupervalutazione: []});
    };

    filtraDevices = () => {

        var postData = {
            id_model_name: this.state.idModelSelezionato,
            id_brand: this.state.idBrandSelezionato,
            id_memory: this.state.idMemoriaSelezionato
        };
        instance.post('/getPriceCheckPrice', postData).then(resp => {
            console.log(resp.data);
            this.setState({priceData: resp.data, prezzoCompleto: resp.data[0].working_price})
        });
    };

    getNegozi = (idAffiliato) => {
        var postData = {
            id_affiliato: idAffiliato,
            id_negozio: null
        };
        instance.post('/getNegozi', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].key = i;
                if (this.state.userLongitude != null) {
                    resp.data[i].distance = this.calcCrow(this.state.userLatitude, this.state.userLongitude, resp.data[i].latitudine, resp.data[i].longitudine).toFixed(1)
                }
            }

            if (this.state.userLongitude != null) resp.data.sort((a, b) => a.distance - b.distance)
            this.setState({negozi: resp.data})
        });
    };

    azzeraFiltri = () => {
        this.setState({idBrandSelezionato: null, idModelSelezionato: null, idMemoriaSelezionato: null, modelli: []})
    };

    goToMaps = (link) => {
        window.open(link, '_blank')
    };

    calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = this.toRad(lat2-lat1);
        var dLon = this.toRad(lon2-lon1);
        var lat1 = this.toRad(lat1)
        var lat2 = this.toRad(lat2)

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c
        return d;
    }

    toRad(Value) {
        return Value * Math.PI / 180;
    }

    render() {

        const getUserLocation = () => {
            // if geolocation is supported by the users browser
            this.setState({showMap: true});

            if (navigator.geolocation) {
                // get the current users location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // save the geolocation coordinates in two variables
                        const { latitude, longitude } = position.coords;
                        // update the value of userlocation variable
                        this.setState({ userLatitude: latitude, userLongitude: longitude });
                        if (this.state.storeToken == '8e4877f4-d90c') this.getNegozi(589);
                    },
                    // if there was an error getting the users location
                    (error) => {
                        console.error('Error getting user location:', error);
                        if (this.state.storeToken == '8e4877f4-d90c') this.getNegozi(589);
                    }
                );
            }
            // if geolocation is not supported by the users browser
            else {
                console.error('Geolocation is not supported by this browser.');
                if (this.state.storeToken == '8e4877f4-d90c') this.getNegozi(589);
            }
        };

        let formMemoria = (
            <Autocomplete
                id="memoria"
                sx={{backgroundColor: '#f4f4f4', margin: '10px 0px 15px 0px', borderRadius: '4px', width: '100%', display: 'inline-flex',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.memoria}
                autoHighlight
                size="small"
                variant="outlined"
                onChange={this.onMemoryChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Capacità Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let formBrand = (
            <Autocomplete
                id="brand"
                sx={{backgroundColor: '#f4f4f4',margin: '10px 0px 15px 0px', borderRadius: '4px', width: '100%', display: 'inline-flex',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.brands}
                autoHighlight
                size="small"
                variant="outlined"
                onChange={this.onBrandsChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Brand Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let formModello = (
            <Autocomplete
                id="modello"
                sx={{backgroundColor: '#f4f4f4', margin: '10px 0px 15px 0px', borderRadius: '4px', width: '100%', display: 'inline-flex',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.modelli}
                autoHighlight
                size="small"
                variant="outlined"
                onChange={this.onModelsChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Modello Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let boxFiltri = (
            <Box style={{textAlign: '-webkit-center'}}>
                <Box className={classes.FiltriBox}>
                    <h3 className={classes.FiltriTitle}>{('Cerca il prezzo')}</h3>
                    {formBrand}
                    {formModello}
                    {formMemoria}
                    <br />
                    <Button style={{margin: '10px', borderRadius: '15px'}} disabled={this.state.idBrandSelezionato == null || this.state.idMemoriaSelezionato == null || this.state.idModelSelezionato == null} clicked={() => this.filtraDevices()}>{('CERCA')}</Button>
                </Box>
            </Box>
        );


        let prezzoCompleto = null;


        let boxResult = null;
        if (this.state.priceData != null && this.state.priceData.length > 0) {
            boxResult = (
                <Box style={{textAlign: '-webkit-center'}}>
                    <Box className={classes.ResultBox}>
                        <h3 className={classes.FiltriTitle}>
                            Portando il tuo <strong> {this.state.priceData[0].brand_name} {this.state.priceData[0].model_name} {this.state.priceData[0].memory} </strong> in uno dei nostri negozi puoi ricevere fino a <br /> <strong> <h1 style={{display: 'contents'}}>{this.state.priceData[0].working_price}</h1> </strong>Euro
                        </h3>
                        {prezzoCompleto}
                    </Box>
                </Box>
            )
        }

        let location = null;
        if (this.state.storeToken)
        location = (
            <div>
                <Button clicked={getUserLocation} style={{margin: '10px'}}>Trova il negozio più vicino a te</Button>
            </div>
        );



        let listaNegozi = null;
        listaNegozi = this.state.negozi.map(el => (
            <List>
                <ListItem secondaryAction={
                    <IconButton edge="end" aria-label="goToMaps" onClick={() => this.goToMaps("http://maps.google.com/?q=Euronics Bruno " + el.indirizzo)}>
                        <AssistantDirectionIcon />
                    </IconButton>
                }>
                    <ListItemIcon>
                        <StorefrontIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={el.ragione_sociale}
                        secondary={<div>
                            <div>{el.indirizzo}</div>
                            <div>Tel: <a href={"tel:+39" + el.telefono}> {el.telefono}</a></div>
                            {this.state.userLongitude != null ? <div>{el.distance} Km dalla tua posizione</div> : null}
                        </div>}
                    />
                </ListItem>
                <Divider className={classes.Shadow}/>
            </List>    ));

        let listaNegoziContainer = (<List>{listaNegozi}</List>);


        let center = this.state.userLongitude != null ? [this.state.userLatitude, this.state.userLongitude] : ["41.91863967655742", "12.480367857620092"];

        const myIcon = new L.Icon({
            iconUrl: ICONS.myMarker,
            iconRetinaUrl: ICONS.myMarker,
            popupAnchor:  [-0, -0],
            iconSize: [25,41],
        });

        let markers = this.state.negozi.map(el => (
            <Marker position={[el.latitudine, el.longitudine]}>
                <Popup>
                    {el.ragione_sociale}<br /><Divider />{el.indirizzo}
                    <Divider />
                    <br />
                    <a href={"http://maps.google.com/?q=Euronics Bruno " + el.indirizzo} target="_blank">Apri in Maps</a>
                </Popup>

            </Marker>
        ));


        let myPosition = null;
        if (this.state.userLongitude !=  null) {
            myPosition = (
                <Marker position={[this.state.userLatitude, this.state.userLongitude]} icon={myIcon}>
                    <Popup>
                        La mia posizione
                    </Popup>
                </Marker>)
        }

        let map = null;
        if (this.state.showMap)
            map = (
                <MapContainer center={center} zoom={5} scrollWheelZoom={true} style={{ height: '50vh' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers}
                    {myPosition}
                </MapContainer>
            );

        return (
            <Auxiliary>
                <div className={classes.PriceCheck}>
                    <h1 className={classes.Title}>PRICE CHECK</h1>
                    <div style={{height: 'auto', width: '100%', textAlign: '-webkit-center'}}>
                        {boxFiltri}
                        {boxResult}
                        {location}
                    </div>
                    {map}
                    {listaNegoziContainer}
                </div>

            </Auxiliary>
        );
    }

}

export default (PublicPriceCheck)
