import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "../../../components/UI/Button/Button";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from '@material-ui/data-grid';
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import DefaultSnackbar from '@mui/material/Snackbar';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../Spedizioni.css";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import instance from "../../../axios-spc";
import { MaterialReactTable } from 'material-react-table';
import Box from "@mui/material/Box";
import FileDownloadIcon from "@mui/material/SvgIcon/SvgIcon";
import styled from 'styled-components'
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IMAGES from "../../../store/images";
import Modal from "../../../components/UI/Modal/Modal";
import ContenutoSpedizione from "../StoricoSpedizioni/ContenutoSpedizione/ContenutoSpedizione";

class SpedizioniPending extends Component{


    state = {
        spedizioni: [
        ],
        headers: [
            {field: 'id', headerName: 'ID', flex: 1},
            {field: 'num_times', headerName: 'NumTimes', flex: 1},
            {field: 'brand_name', headerName: 'Valore', flex: 1},
            {field: 'model_name', headerName: 'Modello', flex: 1},
            {field: 'memory_gb', headerName: 'Memoria', flex: 1},
            {field: 'valore_acquisto', headerName: 'Valore', flex: 1},
            {field: 'color', headerName: 'Colore', flex: 1},
            {
                field: 'conferma',
                headerName: 'Conferma',
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.confermaSpedizione(thisRow["id"], thisRow["num_times"]);
                    };

                    return <Button clicked={onClick}>Conferma</Button>;
                }
            },
        ],
        pageSize: 5,
        confirm: false,
        error: false,
        expandedAccordion: false,
        anchorEl: null,
        storico: []
    };



    componentDidMount() {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio")
        };
        instance.post('/getSpedizioniPending', postData).then(resp => {
            this.setState({spedizioni: resp.data})
        });
        if (localStorage.getItem("role") == 2) {
            this.getSpedizioniMaster()
        } else {
            this.getSpedizioni();
        }
    }

    getSpedizioni = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            data_inizio: null,
            data_fine: null
        };

        instance.post('/getListaSpedizioni', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].data_richiesta_spedizione_magcent = new Date((resp.data[i].data_richiesta_spedizione_magcent));
                resp.data[i].id = resp.data[i].id_spedizione_magcent;
                let visualizza = (<img src={IMAGES.iconVedi} title={"CONTENUTO SPEDIZIONE"} style={{width: '27px', cursor: 'pointer', padding: '2px', marginRight: '5px'}} onClick={(id) => this.openModal(resp.data[i].id)} color="inherit"/>);
                let ddt = (<img src={IMAGES.iconDDT} title={"STAMPA D.D.T."} style={{width: '27px', cursor: 'pointer', padding: '2px', marginRight: '5px'}} onClick={(id) => this.openDdt(resp.data[i].id)} color="inherit"/>);
                let operations = [];
                operations.push(visualizza);
                operations.push(ddt);
                resp.data[i].azioni = operations;
            }
            this.setState({storico: resp.data})
        });
    };

    getSpedizioniMaster = () => {
        var postData = {
            id_affiliato: localStorage.getItem("idAffiliato"),
            data_inizio: null,
            data_fine: null
        };

        instance.post('/getSpedizioniMaster', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].data_richiesta_spedizione_magcent = new Date((resp.data[i].data_richiesta_spedizione_magcent));
                resp.data[i].id = resp.data[i].id_spedizione_magcent;
                let visualizza = (<img src={IMAGES.iconVedi} style={{width: '27px', cursor: 'pointer', padding: '2px', marginRight: '5px'}} onClick={(id) => this.openModal(resp.data[i].id)} color="inherit"/>);
                let ddt = (<img src={IMAGES.iconDDT} title={"STAMPA D.D.T."} style={{width: '27px', cursor: 'pointer', padding: '2px', marginRight: '5px'}} onClick={(id) => this.openDdt(resp.data[i].id)} color="inherit"/>);
                let operations = [];
                operations.push(visualizza);
                operations.push(ddt);
                resp.data[i].azioni = operations;
            }
            this.setState({storico: resp.data})
        });
    };

    openModal = (id) => {
        this.setState({visualizzaScheda: true, idSpedizioneDettaglio: id});
    };

    openDdt = (id) => {
        window.open("https://collaudo.smartphonecash.it/stampaDdt?id=" + id);
        // window.open("http://localhost:3000/stampaDdt?id=" + id);
    };

    openGLSTracking = (tracking) => {
        let trackingUrl = "https://wwwdr.gls-italy.com/XML/get_xml_track.php?locpartenza=M6&numrit=" + tracking.substring(2) + "&CodCli=2397";
        window.open(trackingUrl);
    };

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaScheda: false, idSpedizioneDettaglio: null})
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confirm: false, error: false});
    };

    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        this.setState({expandedAccordion: expanded})
    }

    confermaSpedizione = (id, numTimes) => {
        var postData = {
            serial_number: id,
            num_times: numTimes,
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/setSpedito', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                let idx = this.state.spedizioni.findIndex( (el) => el.id === id );
                const updatedState = {...this.state};
                const updatedSpedizione = [...updatedState.spedizioni];
                updatedSpedizione.splice(idx, 1);
                this.setState({spedizioni: updatedSpedizione, confirm: true});
            }
        });
    };


    handleMenu = (event) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null})
    };

    render() {

        let schedaCompleta = null;
        if (this.state.visualizzaScheda) {
            schedaCompleta = (
                <Modal show={this.state.visualizzaScheda} modalClosed={this.visualizzaSchedaCancelHandler} title={"Spedizione " + this.state.idSpedizioneDettaglio} >
                    <ContenutoSpedizione id={this.state.idSpedizioneDettaglio} />
                </Modal>
            )
        }


        const newColumnsStorico = [
            {accessorKey: 'id', header: 'D.D.T.', editable: false},
            {accessorKey: 'data_richiesta_spedizione_magcent', header: 'Data', editable: false, Cell: ({ cell }) => cell.getValue()?.toLocaleDateString()},
            {accessorKey: 'motivo_spedizione_magcent', header: 'Motivo', editable: false},
            {accessorKey: 'tracking_spedizione', header: 'Track', editable: false},
            {accessorKey: 'numero_devices', header: 'Num. Devices', editable: false},
            {accessorKey: 'azioni', header: 'Azioni'},
        ];

        const newColumnsMaster = [
            {accessorKey: 'id', header: 'D.D.T.', editable: false, size: 50},
            {accessorKey: 'data_richiesta_spedizione_magcent', header: 'Data', editable: false, Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), size: 75},
            {accessorKey: 'negozio_spedizione', header: 'Negozio', editable: false, size: 250},
            {accessorKey: 'motivo_spedizione_magcent', header: 'Motivo', editable: false},
            {accessorKey: 'tracking_spedizione', header: 'Track', editable: false},
            {accessorKey: 'numero_devices', header: 'Num. Devices', editable: false, size: 75},
            {accessorKey: 'azioni', header: 'Azioni', size: 75},
        ];

        let visibleColumns = {
            tracking_spedizione: false
        };

        if (localStorage.getItem("role") == 2) {
            visibleColumns = {
                tracking_spedizione: false,
                motivo_spedizione_magcent: false
            };
        }

        let listaStoricoSpedizioni = (
            <MaterialReactTable
                columns={localStorage.getItem("role") === 2 ? newColumnsMaster : newColumnsStorico}
                data={this.state.storico}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [10, 25, 50, 100]
                }}
                initialState={{ columnPinning: { right: ['azioni'] }, columnVisibility: visibleColumns, density: 'compact'  }}
                layoutMode="grid"
            />
        );

        let confirmAlert = (
            <Snackbar open={this.state.confirm} closed={this.handleClose} style={{fontSize: 'larger'}} severity="success">Spedizione creata correttamente!</Snackbar>
        );
        let errorAlert = (
            <Snackbar vertical={'top'} horizontal={'center'} open={this.state.error} closed={this.handleClose} style={{fontSize: 'larger'}} severity="error">Non è possibile creare la spedizione con i device selezionati.</Snackbar>
        );
        // let errorAlert = (
        //     <DefaultSnackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.error} onClose={this.handleClose}  severity="error">Non è possibile creare la spedizione con i device selezionati.</DefaultSnackbar>
        // );

        const handleExportData = (data) => {
            let deviceDaSpedire = data.map(device => device.original);
            let spedizioneProgrammabileNumero = false;
            let spedizioneProgrammabileValore = true;
            if (data.length >= 3) {
                spedizioneProgrammabileNumero = true;
            } else {
                deviceDaSpedire.forEach((element) => {
                    spedizioneProgrammabileValore = spedizioneProgrammabileValore && element.valore_acquisto >= 300;
                })
            }

            if (spedizioneProgrammabileNumero || spedizioneProgrammabileValore) {
                var postData = {
                    numero_device: data.length,
                    id_negozio: localStorage.getItem("idNegozio")
                };
                instance.post('/setSpedizioniManuali', postData).then(resp => {
                    if (resp.data[0].ret_code === 0) {
                        deviceDaSpedire.forEach((element) => {
                            let postData = {
                                serial_number: element.serial_number,
                                num_times: element.num_times,
                                id_operatore: localStorage.getItem("userId"),
                                id_ddt: resp.data[0].id_spedizione
                            };

                            instance.post('/setSpedito', postData).then(resp => {
                                console.log(resp);
                                this.setState({confirm: true, error: false});
                                var postDataGet = {
                                    id_negozio: localStorage.getItem("idNegozio")
                                };
                                instance.post('/getSpedizioniPending', postDataGet).then(response => {
                                    this.setState({spedizioni: response.data});
                                    this.getSpedizioni();
                                });
                            });
                        })
                    }
                });
            } else {
                this.setState({confirm: false, error: true})
            }
        };

        const newColumns = [
            {accessorKey: 'serial_number', header: 'ID', editable: false},
            {accessorKey: 'brand_name', header: 'Brand', editable: false},
            {accessorKey: 'model_name', header: 'Modello', editable: false},
            {accessorKey: 'imei', header: 'IMEI', editable: false},
            {accessorKey: 'memory_gb', header: 'Memoria', editable: false},
            {accessorKey: 'valore_acquisto', header: 'Valore Acquisto', editable: false},
        ];

        let listaSpedizioniPending = null;

        if (localStorage.getItem("role") < 2) {
            listaSpedizioniPending = (
                <Box style={{textAlign: '-webkit-center'}}>
                    <Box className={classes.ResultBox}>
                        <h3 className={classes.FiltriTitle}>
                            SPEDIZIONI IN ATTESA
                        </h3>
                        <MaterialReactTable
                            columns={newColumns}
                            data={this.state.spedizioni}
                            enableRowSelection
                            // enableColumnOrdering
                            enablePinning
                            enableGlobalFilter={true}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [10, 25, 50, 100]
                            }}
                            // onRowSelectionChange={() => setRowSelection()}
                            initialState={{density: 'compact'}}
                            layoutMode="grid"
                            renderTopToolbarCustomActions={({table}) => (
                                <Box
                                    sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        color="primary"
                                        clicked={() => handleExportData(table.getSelectedRowModel().rows)}
                                        variant="contained"
                                    >
                                        {'GENERA SPEDIZIONE'}
                                    </Button>
                                    <IconButton
                                        onClick={(event) => this.handleMenu(event)}
                                        size="small"
                                        sx={{ml: 2}}
                                        aria-controls={this.state.anchorEl ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={this.state.anchorEl ? 'true' : undefined}
                                    >
                                        <Avatar sx={{width: 32, height: 32}}>?</Avatar>
                                    </IconButton>
                                    <Menu
                                        anchorEl={this.state.anchorEl}
                                        id="account-menu"
                                        open={this.state.anchorEl}
                                        onClose={() => this.handleCloseMenu()}
                                        onClick={() => this.handleCloseMenu()}
                                        slotProps={{
                                            paper: {
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            },
                                        }}
                                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                    >
                                        <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                                            <strong>Condizioni per generare la spedizione</strong>
                                        </MenuItem>
                                        <Divider/>
                                        <div style={{maxWidth: '400px', padding: '5px'}}>
                                            <p>Per poter generare una spedizione è necessario inserire almeno 3 device
                                                oppure almeno un device con valore superiore a 300 euro</p>
                                        </div>
                                    </Menu>
                                </Box>
                            )}
                        />
                    </Box>
                </Box>
            );
        }


        return (
            <Auxiliary>
                {confirmAlert}
                {errorAlert}
                {schedaCompleta}
                {listaSpedizioniPending}
                <Box style={{textAlign: '-webkit-center'}}>
                    <Box className={classes.ResultBox}>
                        <h3 className={classes.FiltriTitle}>
                            STORICO SPEDIZIONI
                        </h3>
                        {listaStoricoSpedizioni}
                    </Box>
                </Box>
            </Auxiliary>
        );
    }
}



export default (SpedizioniPending);
