import React from 'react'
import {Button, TextField} from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import styled from 'styled-components'
import Checkbox from "@material-ui/core/Checkbox";

const textField = (props) => {

    let inputElement = null;
    let validationError = null;

    if (props.error) {
        console.log(props)
    }

    if (props.error && props.touched) {
        validationError = <h1>{props.helperText}</h1>;
    }
    if (!props.invalid && props.touched) {
        validationError = null
    }

    switch (props.type) {
        case ('input'):
            inputElement = <TextField
                // fullWidth
                inputProps={props.inputProps}
                input
                disabled={props.disabled}
                key={props.key}
                label={props.label}
                name={props.name}
                variant="outlined"
                value={props.value}
                type={props.type}
                style={props.style}
                error={props.error}
                touched={props.touched}
                helperText={props.helperText}
                id={props.key}
                {...props.elementConfig}
                size={"small"}
                onClick={props.clicked}
                onChange={props.changed}
                onBlur={props.blurred}
                InputProps={props.InputProps}
            />;
            break;
        case ('date'):
            inputElement = <TextField
                date
                inputProps={{max: '2100-12-31'}}
                key={props.key}
                label={props.label}
                name={props.name}
                variant="outlined"
                value={props.value}
                type={props.type}
                style={props.style}
                error={props.error}
                touched={props.touched}
                helperText={props.helperText}
                id={props.key}
                {...props.elementConfig}
                size={"small"}
                onChange={props.changed}
                InputProps={{max: '2100-12-31'}}
                max={"2100-12-31"}
            />;
            break;
        // case ('date'):
        //     inputElement =
        //         (<LocalizationProvider dateAdapter={AdapterDayjs}>
        //         <DatePicker
        //             inputProps={props.inputProps}
        //             input
        //             disabled={props.disabled}
        //             key={props.key}
        //             label={props.label}
        //             name={props.name}
        //             variant="outlined"
        //             value={props.value}
        //             type={props.type}
        //             style={props.style}
        //             error={props.error}
        //             touched={props.touched}
        //             helperText={props.helperText}
        //             id={props.key}
        //             {...props.elementConfig}
        //             size={"small"}
        //             onClick={props.clicked}
        //             onChange={props.changed}
        //             onBlur={props.blurred}
        //             InputProps={props.InputProps}
        //        />;
        //         </LocalizationProvider>);
        //     break;
        case ('datetime-local'):
            inputElement = <TextField
                date
                inputProps={{max: '2100-12-31T23:59'}}
                key={props.key}
                label={props.label}
                name={props.name}
                variant="outlined"
                value={props.value}
                type={props.type}
                style={props.style}
                error={props.error}
                touched={props.touched}
                helperText={props.helperText}
                id={props.key}
                {...props.elementConfig}
                size={"small"}
                onChange={props.changed}
                InputProps={{max: '2100-12-31T23:59'}}
            />;
            break;
        // case ('textarea'):
        //     inputElement = <TextField inputComponent="textarea" {...props.elementConfig} value={props.value} onChange={props.changed}/>;
        //     break;
        case ('select'):
            inputElement = (
                <TextField
                    select
                    key={props.key}
                    label={props.label}
                    name={props.name}
                    variant="outlined"
                    value={props.value}
                    type={props.type}
                    style={props.style}
                    error={props.error}
                    touched={props.touched}
                    helperText={props.helperText}
                    id={props.key}
                    {...props.elementConfig}
                    size={"small"}
                    onChange={props.changed}
                    InputProps={props.InputProps}
                >
                    {props.options.map(op => (
                        <MenuItem key={op.value} value={op.value} disabled={op.disabled}>{op.displayValue}</MenuItem>
                    ))}
                </TextField>
            )
            // case ('select'):
            //     inputElement = (
            //         <Autocomplete
            //             disablePortal
            //             options={props.options}
            //             style={props.style}
            //             renderInput={() =>
            //                 <TextField
            //                     select
            //                     key={props.key}
            //                     label={props.label}
            //                     name={props.name}
            //                     variant="outlined"
            //                     value={props.value}
            //                     type={props.type}
            //                     style={props.style}
            //                     error={props.error}
            //                     touched={props.touched}
            //                     helperText={props.helperText}
            //                     id={props.key}
            //                     {...props.elementConfig}
            //                     size={"small"}
            //                     onChange={props.changed} />
            //                     }
            //     />
            // )
            break;
        case ('radio'):
            inputElement = (
                <FormControl style={{backgroundColor: 'transparent', margin: '10px', width: '350px', borderRadius: '4px', border: '0px'}}>
                    <FormLabel id="demo-controlled-radio-buttons-group" style={{marginTop: '12px'}}>{props.label}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={props.defaultValue}
                        name="radio-buttons-group"
                        style={{marginLeft: '25%', marginTop: '-18px'}}
                        onChange={props.changed}
                    >
                        {props.options.map(op => (
                            <FormControlLabel value={op.value} control={<Radio />} label={op.displayValue} />
                        ))}
                        {props.InputProps.endAdornment}
                    </RadioGroup>

                </FormControl>

            );
            break;
        case ('checkbox'):
            inputElement = (
                <FormControl style={{padding:'0 0 0 10px', height: '35px', backgroundColor: 'transparent', margin: '10px', width: '340px', borderRadius: '4px', border: '0px', textAlign: 'center'}}>
                    <FormControlLabel style={{textAlign: 'center'}} control={<Checkbox style={{textAlign: 'center'}} onClick={props.changed} checked={props.checked} />} label={props.label} />
                    {props.InputProps.endAdornment}
                </FormControl>
            );
            break;
        case ('button'):
            inputElement = (
                <Button variant="contained" color="primary" type="button" onClick={props.clicked} disabled={props.disabled} style={props.style}>AGGIUNGI POS</Button>
            );
            break;
        case ('number'):
            inputElement = <TextField
                // fullWidth
                inputProps={props.inputProps}
                input
                disabled={props.disabled}
                key={props.key}
                label={props.label}
                name={props.name}
                variant="outlined"
                value={props.value}
                type={props.type}
                style={props.style}
                error={props.error}
                touched={props.touched}
                helperText={props.helperText}
                id={props.key}
                {...props.elementConfig}
                size={"small"}
                onClick={props.clicked}
                onChange={props.changed}
                onBlur={props.blurred}
                InputProps={props.InputProps}
            />;
            break;
        default:
            inputElement = <TextField
                // fullWidth
                name={props.name}
                style={props.style}
                id={props.id}
                input
                variant="outlined"
                value={props.value}
                label={props.label}
                type={props.type}
                size={"small"}
                pattern={props.pattern}
                {...props.elementConfig}
                onChange={props.changed} />;
    }

    return (
        <Auxiliary>
            {inputElement}
            {validationError}
        </Auxiliary>
    )
};

export default textField;
