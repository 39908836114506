import React, {Component} from "react";
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../GetDevices.css";
import instance from "../../../axios-spc";
import {MaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import LocalShippingIcon from "@mui/material/SvgIcon/SvgIcon";
import Spinner from "../../../components/UI/Spinner/Spinner";
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';


class DevicesLiquidati extends Component{

    state = {
        devices: [],
        bbkPackages: [],
        pageSize: 5,
        idDeviceDettaglio: null,
        visualizzaDocumento: false,
        confirmBuyBack: false,
        isRichiestaBuyBack: false,
        isValutazioneDevice: false,
        valoreDevice: 0,
        noteDevice: null,
        confirm: false,
        confirmValutazione: false,
        isLoading: false,
        anchorEl: null,


    };


    componentDidMount() {
        this.getDevices();
    }

    getDevices = () => {
        this.setState({isLoading: true});
        var postData = {
            id_negozio: localStorage.getItem("idNegozio") == 0 || localStorage.getItem("role") ? null : localStorage.getItem("idNegozio"),
            id_stato_device: 5
        };

        instance.post('/getDevice', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto)).toLocaleDateString();
                resp.data[i].modelloCompleto = resp.data[i].brand_name + " " + resp.data[i].model_name.replace(resp.data[i].brand_name, '') + " (" + resp.data[i].memory_gb + " GB)";
                let operations = [];
                let liquidato = (<EuroSymbolIcon titleAccess={"LIQUIDA"} onClick={(id) => this.liquidato(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                operations.push(liquidato);
                resp.data[i].azioni = operations;
            }
            this.setState({devices: resp.data, isLoading: false})
        });
    };

    liquidato = (id, num_times) => {
        var postData = {
            serial_number: id,
            id_operatore: localStorage.getItem("userId"),
            num_times: num_times
        };
        instance.post('/setStatusLiquidato', postData).then(resp => {
            // axios.post('http://localhost:8989/api/setStatusValutato', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmValutazione: true});
                this.getDevices();
            }
        });
    };

    handleMenu = (event) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null})
    };

    render() {

        let spinner = null;
        if (this.state.isLoading) {
            spinner = <Spinner />
        }

        let confirmValutazione = (
            <Snackbar open={this.state.confirmValutazione} closed={this.handleClose} severity="success">Device liquidato con successo!</Snackbar>
        );

        const newColumns = [
            {accessorKey: 'data_acquisto', header: 'Data Acquisto', size: 100},
            {accessorKey: 'modelloCompleto', header: 'Device'},
            {accessorKey: 'imei', header: 'IMEI'},
            {accessorKey: 'valore_acquisto', header: 'Valore', size: 75, Cell: ({ cell }) => cell.getValue() + " €"},
            {accessorKey: 'negozio_acquisto', header: 'Negozio Acquisto', size: 250},
            {accessorKey: 'stato_device', header: 'Stato'},
            {accessorKey: 'azioni', header: 'Azioni'},
        ]

        let sped = (
            <MaterialReactTable
                columns={newColumns}
                data={this.state.devices}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 50, 100]
                }}
                initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact', pagination: { pageSize: 5}  }}
                layoutMode="grid"
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <IconButton
                            onClick={(event) => this.handleMenu(event)}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={this.state.anchorEl ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={this.state.anchorEl ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}>?</Avatar>
                        </IconButton>
                        <Menu
                            anchorEl={this.state.anchorEl}
                            id="account-menu"
                            open={this.state.anchorEl}
                            onClose={() => this.handleCloseMenu()}
                            onClick={() => this.handleCloseMenu()}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                                <strong>Legenda Azioni</strong>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                                <EuroSymbolIcon style={{width: '27px', cursor: 'default', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} /> Liquida device
                            </MenuItem>
                            <Divider />
                            <div style={{maxWidth: '400px', padding: '5px'}}>
                                <p>In questa tabella vengono visualizzati solamente i device in stato VALUTATO</p>
                            </div>
                        </Menu>
                    </Box>
                )}
            />
        );

        return (
            <Box style={{textAlign: '-webkit-center'}}>
                {confirmValutazione}
                <Box className={classes.ResultBox}>
                    <h1 className={classes.FiltriTitle}>
                        DEVICES VALUTATI
                    </h1>
                    {spinner}
                    {sped}
                </Box>
            </Box>
        );
    }
}



export default (DevicesLiquidati);
