import React, {Component} from "react";
import Button from '../../components/UI/Button/Button'
import instance from "../../axios-spc";
import axios from "axios";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Spinner from "../../components/UI/Spinner/Spinner";
import SignaturePad from 'react-signature-canvas';
import classes from './TakePhoto.css'
import IMAGES from '../../store/images'
import queryString from 'query-string';
import Compress from 'react-image-file-resizer'
import html2pdf from "html2pdf.js";
import JsBarcode from "jsbarcode";


class TakePhoto extends Component {

    state = {
        fotoFronte: null,
        fotoFronteBK: null,
        confermaFronte: false,
        fotoRetro: null,
        fotoRetroBK: null,
        confermaRetro: false,
        fotoConfermate: false,
        firmaUno: null,
        confermaFirmaUno: false,
        firmaDue: null,
        confermaFirmaDue: false,
        firmaTre: null,
        confermaFirmaTre: false,
        loading: false,
        cliente: null,
        device: null,
        ricevuta: null,
        operazioniCompletate: false,
        ipAddress: null,
        numTimes: 0
    };

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        var postData = {
            id_negozio: params.negozio,
            cognome: params.c,
            codice_fiscale: params.cf,
            ragione_sociale: null,
            partita_iva: null
        };

        let postDataRicevuta = {
            serial_number: params.device,
            num_times: params.nt,
            id_negozio: params.negozio,
            id_operatore: localStorage.getItem("userId")
        };

        let cliente = null;
        let ricevuta = null;
        let axiosConfig = {
            headers: {
                'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };
        axios.get('https://api.ipify.org?format=json', axiosConfig)
            .then(response => {
                this.setState({ipAddress: response.data.ip});

            })
            .catch(error => {
                console.log(error);
            });
        instance.post('/getRicevuta', postDataRicevuta)
            .then(response => {
                ricevuta = response.data[0];
                this.setState({ricevuta: ricevuta});

                var postData = {
                    serial_number: params.device,
                    id_negozio: params.negozio
                };
                instance.post('/getDevice', postData, axiosConfig).then(response =>{
                    this.setState({device: response.data[0]})
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    sigPad = {};

    salvaImmagine = (id, fileFirma) => {
        this.setState({loading: true});
        // this.setState({firmaUno: fileFirma});
        let payloadImg = id === 'fronte' ? this.state.fotoFronte : id === 'retro' ? this.state.fotoRetro : fileFirma;
        let nomeFile = "";
        if (id === 'fronte') nomeFile = "fronte_" + this.state.ricevuta.codice_pratica;
        if (id === 'retro') nomeFile = "retro_" + this.state.ricevuta.codice_pratica;
        if (id === 'firma1') nomeFile = "firma1_" + this.state.ricevuta.codice_pratica;
        if (id === 'firma2') nomeFile = "firma2_" +this.state.ricevuta.codice_pratica;
        if (id === 'firma3') nomeFile = "firma3_" + this.state.ricevuta.codice_pratica;
        let postData = {
            image: payloadImg,
            fileName: nomeFile
        } ;
        instance.post('/uploadFile', postData)
            .then(response => {
                if (id === 'fronte') this.setState({fotoFronte: null, confermaFronte: true});
                if (id === 'retro') this.setState({fotoRetro: null, confermaRetro: true});
                if (id === 'firma1') this.setState({confermaFirmaUno: true});
                if (id === 'firma2') this.setState({confermaFirmaDue: true});
                if (id === 'firma3') this.setState({confermaFirmaTre: true});
                this.setState({loading: false});
                console.log("ok")
            })
            .catch(error => {
                console.log(error);
            });

    };

    annullaFoto = (id) => {
        if (id === 'fronte') this.setState({fotoFronte: null, confermaFotoFronte: false});
        if (id === 'retro') this.setState({fotoRetro: null, confermaFotoRetro: false})
    };

    salvaImmagineAnteprima = (img, id) => {
        if (id === 'fronte') this.setState({fotoFronte: img, fotoFronteBK: img});
        if (id === 'retro') this.setState({fotoRetro: img, fotoRetroBK: img});
    };

    saveCanvas = (id) => {
        const base64Firma = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
        id === 'firma1' ? this.setState({firmaUno: base64Firma}) : id === 'firma2' ? this.setState({firmaDue: base64Firma}) : this.setState({firmaTre: base64Firma});
        id === 'firma1' ? this.salvaImmagine('firma1', base64Firma) : id === 'firma2' ? this.salvaImmagine('firma2', base64Firma) : this.salvaImmagine('firma3', base64Firma);
        this.clear(id);
    };

    onFileResize = (e, id) => {
        const file = e.target.files[0];

        Compress.imageFileResizer(
            file,
            800,
            800,
            "PNG",
            75,
            0,
            (uri) => {
                this.salvaImmagineAnteprima(uri, id);
            },
            "base64"
        );
    };

    compila = (ricevuta) => {
        let doc = document.getElementById("ricevuta_acquisto");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";

        const nome = svgElement.getElementById("nome");
        const device = svgElement.getElementById("device");
        const color = svgElement.getElementById("color");
        const memory = svgElement.getElementById("memory");
        const seriale = svgElement.getElementById("seriale");
        const numero_pratica = svgElement.getElementById("numero_pratica");
        const data_acquisto = svgElement.getElementById("data_acquisto");
        const data_generazione = svgElement.getElementById("data_generazione");
        const prezzo = svgElement.getElementById("prezzo");
        const modello = svgElement.getElementById("modello");
        const indirizzo = svgElement.getElementById("indirizzo");
        const telefono = svgElement.getElementById("telefono");
        const mail = svgElement.getElementById("mail");
        const documento = svgElement.getElementById("documento");
        const cognome = svgElement.getElementById("cognome");
        const codice_fiscale = svgElement.getElementById("codice_fiscale");
        const imei = svgElement.getElementById("imei");

        var i = ricevuta.nome_cedente.indexOf(' ');
        const anagrafica = [ricevuta.nome_cedente.substring(0, i), ricevuta.nome_cedente.substring(i+1)];

        nome.innerHTML = anagrafica[0];
        device.innerHTML = this.state.ricevuta.oggetto;
        color.innerHTML = this.state.device.color;
        memory.innerHTML = this.state.device.memory_gb;
        seriale.innerHTML = this.state.device.serial_number;
        numero_pratica.innerHTML = this.state.ricevuta.codice_pratica;
        data_acquisto.innerHTML = this.formatDate(this.state.ricevuta.data_acquisto);
        data_generazione.innerHTML = this.formatDate(new Date().toJSON().slice(0,10));
        prezzo.innerHTML = this.state.ricevuta.prezzo_acquisto;
        modello.innerHTML = this.state.device.model_name;
        indirizzo.innerHTML = this.state.ricevuta.indirizzo_cedente;
        telefono.innerHTML = this.state.ricevuta.telefono_cedente;
        mail.innerHTML = this.state.ricevuta.email_cedente;
        documento.innerHTML = this.state.ricevuta.tipo_documento_cedente.replace("_", " ") + " - " + this.state.ricevuta.numero_documento_cedente;
        codice_fiscale.innerHTML = this.state.ricevuta.codice_fiscale_cedente;
        cognome.innerHTML = anagrafica[1];
        imei.innerHTML = this.state.device.imei;

        if (this.state.ricevuta.id_tipo_contratto === 1 || this.state.ricevuta.id_tipo_contratto === 2) {
            svgElement.getElementById("bollo1").style.display = "none";
            // svgElement.getElementById("bollo2").style.display = "none";
            // svgElement.getElementById("bollo3").style.display = "none";
            // svgElement.getElementById("bollo4").style.display = "none";
        }
        if (this.state.ricevuta.id_tipo_contratto === 3 || this.state.ricevuta.id_tipo_contratto === 4) {
            svgElement.getElementById("bollo1").style.display = "none";
            svgElement.getElementById("bollo2").style.display = "none";
            svgElement.getElementById("bollo3").style.display = "none";
            svgElement.getElementById("bollo4").style.display = "none";
        }
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    inserisciDatiBuono = () => {
        let doc = document.getElementById("buono");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const valore_buono_stampato = svgElement.getElementById("valore_buono_stampato");
        const seriale_buono_stampato = svgElement.getElementById("seriale_buono_stampato");
        const data_scadenza_stampato = svgElement.getElementById("data_scadenza_stampato");
        const data_emissione_stampato = svgElement.getElementById("data_emissione_stampato");
        const frase_affiliato = svgElement.getElementById("frase_affiliato");

        valore_buono_stampato.innerHTML = this.state.ricevuta.prezzo_acquisto + " €";
        seriale_buono_stampato.innerHTML = this.state.ricevuta.coupon_serial_number;
        data_scadenza_stampato.innerHTML = this.formatDate(this.state.ricevuta.data_scadenza_coupon);
        data_emissione_stampato.innerHTML = this.formatDate(this.state.ricevuta.data_acquisto);
        let frasi = this.state.ricevuta.coupon_text.split('<LI>');
        frase_affiliato.textContent = frasi[7].split("esclusivamente")[1].split("</LI>")[0];
        var barcode = svgElement.getElementById("barcode");
        JsBarcode(barcode, this.state.ricevuta.coupon_serial_number, {
            displayValue: false
        });
        barcode.setAttributeNS(null, 'x','314.867');
        barcode.setAttributeNS(null, 'y','358.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','65.66');
    };

    compilaFirma = (ricevuta) => {
        let doc = document.getElementById("ricevuta_acquisto_firma");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";

        if (this.state.ricevuta.is_coupon) {
            const punto_contanti = svgElement.getElementById("punto_contanti").style.display = "none";
            const frase_contanti = svgElement.getElementById("frase_contanti").style.display = "none";
            const valore_contanti = svgElement.getElementById("valore_contanti").style.display = "none";
            const oppure = svgElement.getElementById("oppure").style.display = "none";
            const importo_contanti = svgElement.getElementById("importo_contanti").style.display = "none";
            const valore_buono = svgElement.getElementById("valore_buono").innerHTML = "";
            const importo_buono = svgElement.getElementById("importo_buono").innerHTML = ricevuta.prezzo_acquisto;
            const data_scadenza_buono = svgElement.getElementById("data_scadenza_buono").innerHTML = this.state.ricevuta.data_scadenza_coupon.substring(0, 10);
            const punto_buono_finale = svgElement.getElementById("punto_buono_finale").innerHTML = "";
            const punto_virgola = svgElement.getElementById("punto_virgola").style.display = "none";
        } else {
            const punto_buono = svgElement.getElementById("punto_buono").style.display = "none";
            const buono_riga_uno = svgElement.getElementById("buono_riga_uno").style.display = "none";
            const buono_scadenza = svgElement.getElementById("buono_scadenza").style.display = "none";
            const buono_riga_due = svgElement.getElementById("buono_riga_due").style.display = "none";
            const valore_buono = svgElement.getElementById("valore_buono").innerHTML = "";
            const oppure = svgElement.getElementById("oppure").style.display = "none";
            const importo_buono = svgElement.getElementById("importo_buono").innerHTML = "";
            const data_scadenza_buono = svgElement.getElementById("data_scadenza_buono").innerHTML = "";
            const punto_buono_finale = svgElement.getElementById("punto_buono_finale").innerHTML = "";
            const importo_contanti = svgElement.getElementById("importo_contanti");
            importo_contanti.innerHTML = ricevuta.prezzo_acquisto;
        }

        const data_firma = svgElement.getElementById("data_firma");
        data_firma.innerHTML = this.formatDate(ricevuta.data_acquisto);
        const luogo_firma = svgElement.getElementById("luogo_firma");
        luogo_firma.innerHTML = ricevuta.luogo_acquisto;

        window.scrollTo(0,document.body.scrollHeight);
    };

    compilaFirmaDue = (ricevuta) => {
        let doc = document.getElementById("ricevuta_acquisto_firma_due");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const luogo_firma_due = svgElement.getElementById("luogo_firma_due");
        luogo_firma_due.innerHTML = ricevuta.luogo_acquisto;
        const data_firma_due = svgElement.getElementById("data_firma_due");
        data_firma_due.innerHTML = this.formatDate(ricevuta.data_acquisto);

        window.scrollTo(0,document.body.scrollHeight);
    };

    inserisciScansioni = (ricevuta) => {
        let doc = document.getElementById("scansioni");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";

        var scansioneUno = document.createElementNS(NS, "image");
        scansioneUno.setAttributeNS('http://www.w3.org/1999/xlink', "href", this.state.fotoFronteBK);
        scansioneUno.setAttributeNS(null,'height','288.18');
        scansioneUno.setAttributeNS(null,'width','214.37');
        scansioneUno.setAttributeNS(null,'x','89.56');
        scansioneUno.setAttributeNS(null,'y','478.56');
        scansioneUno.setAttributeNS(null, 'visibility', 'visible');
        svgElement.appendChild(scansioneUno);

        var scansioneDue = document.createElementNS(NS, "image");
        scansioneDue.setAttributeNS('http://www.w3.org/1999/xlink', "href", this.state.fotoRetroBK);
        scansioneDue.setAttributeNS(null,'height','288.18');
        scansioneDue.setAttributeNS(null,'width','214.37');
        scansioneDue.setAttributeNS(null,'x','346.14');
        scansioneDue.setAttributeNS(null,'y','478.56');
        scansioneDue.setAttributeNS(null, 'visibility', 'visible');
        svgElement.appendChild(scansioneDue);

        window.scrollTo(0,document.body.scrollHeight);

    };

    closeWindow = () => {
        window.close();
    };

    clear = (id) => {
        if (id === 'firma3') {
            let doc = document.getElementById("scansioni");
            let svgDoc = doc.contentDocument;
            let svgElement = svgDoc.getElementById("Livello_1");
            var firmaPrivacyDue = svgElement.getElementById("privacy_due_si").textContent === 'x' ? 1 : 0;
            var firmaPrivacyTre = svgElement.getElementById("privacy_tre_si").textContent === 'x' ? 1 : 0;
            var pathFronte = "fronte_" + this.state.ricevuta.codice_pratica;
            var pathRetro = "retro_" + this.state.ricevuta.codice_pratica;
            var pathFirma1 = "firma1_" + this.state.ricevuta.codice_pratica;
            var pathFirma2 = "firma2_" +this.state.ricevuta.codice_pratica;
            var pathFirma3 = "firma3_" + this.state.ricevuta.codice_pratica;
            const postData = {
                serial_number: this.state.device.serial_number,
                num_times: this.state.device.num_times,
                fronte_documento: pathFronte,
                retro_documento: pathRetro,
                firma_contratto_uno: pathFirma1,
                firma_contratto_due: pathFirma2,
                firma_privacy: pathFirma3,
                secondo_flag: firmaPrivacyDue,
                terzo_flag: firmaPrivacyTre,
            };

            instance.post('/setRicevuta', postData)
                .then(response => {

                    let params = queryString.parse(this.props.location.search);
                    let privacyData = {
                        id_cliente: params.idc,
                        id_negozio: params.negozio,
                        ip: this.state.ipAddress,
                        flag_due: firmaPrivacyDue,
                        flag_tre: firmaPrivacyTre,
                    };
                    instance.post('/setPrivacy', privacyData)
                        .then(response => {
                            this.setState({operazioniCompletate: true});
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }

        this.sigPad.clear()
    };

    render() {

        let photoFronteForm = null;
        if (this.state.fotoFronte == null && !this.state.confermaFronte) {
            photoFronteForm = (
                <div>
                    <label>Scatta foto della fronte del documento</label>
                    <input type="file" accept="image/*" capture="environment" onChange={(ev, id) => this.onFileResize(ev, 'fronte')}/>
                </div>
            );
        }
        let anteprimaFronte = null;
        if (this.state.fotoFronte !== null) {
            photoFronteForm = null;
            anteprimaFronte = (
                <Auxiliary>
                    <div>
                        <p>Fronte Documento</p>
                        <img src={this.state.fotoFronte} style={{maxWidth: 250}}/>
                    </div>
                    <div>
                        <Button clicked={(id) => this.salvaImmagine('fronte')}>Salva Foto</Button>
                        <hr />
                        <Button clicked={() =>this.annullaFoto('fronte')}>Scarta</Button>
                    </div>
                </Auxiliary>
            )
        }

        let photoRetroForm = null;
        if (this.state.confermaFronte && !this.state.confermaRetro) {
            photoRetroForm = (
                <Auxiliary>
                    <p>Foto fronte ok</p>
                    <div>
                        <label>Scatta foto del retro del documento</label>
                        <input type="file" accept="image/*" capture="environment" onChange={(ev, id) => this.onFileResize(ev, 'retro')}/>
                    </div>
                </Auxiliary>
            );
        }

        let anteprimaRetro = null;
        if (this.state.fotoRetro !== null) {
            photoRetroForm = null;
            anteprimaRetro = (
                <Auxiliary>
                    <p>Foto fronte ok</p>
                    <div>
                        <p>Retro Documento</p>
                        <img src={this.state.fotoRetro} style={{maxWidth: 250}}/>
                    </div>
                    <div>
                        <Button clicked={(id) => this.salvaImmagine('retro')}>Salva Foto</Button>
                        <hr />
                        <Button clicked={() =>this.annullaFoto('retro')}>Scarta</Button>
                    </div>
                </Auxiliary>
            )
        }

        if (this.state.confermaRetro) {
            photoRetroForm = (
                <div>
                    <p>Foto fronte ok</p>
                    <p>Foto retro ok</p>
                </div>
            )
        }

        let spinner = null;
        if (this.state.loading) {
            spinner = (
                <Spinner/>
            )
        }

        let canvas = null;
        if (this.state.confermaRetro) {
            canvas = (
                <Auxiliary className={classes.Centered}>
                    <div className={classes.DocContainer}>
                        <object data={IMAGES.doc1} type="image/svg+xml" id="pagina_uno"/>
                        <object data={IMAGES.doc2} id="ricevuta_acquisto" type="image/svg+xml" onLoad={() => this.compila(this.state.ricevuta)}/>
                        <object data={IMAGES.doc3} type="image/svg+xml" id="pagina_tre"/>
                        <object data={IMAGES.doc4} id="ricevuta_acquisto_firma" type="image/svg+xml" onLoad={() => this.compilaFirma(this.state.ricevuta)} />
                    </div>
                    <div className={classes.Container} id="canvas_firma1" onLoad={() => this.scroll()}>
                        <div className={classes.SigContainer} style={{border: '2px solid green'}}>
                            <SignaturePad canvasProps={{className: classes.SigPad}} ref={(ref) => {this.sigPad = ref}}/>
                            <br/>
                        </div>
                        <Button clicked={() => {this.clear();}}>CANCELLA</Button>
                        <Button clicked={() => {this.saveCanvas('firma1');}}>SALVA FIRMA</Button>
                    </div>
                </Auxiliary>
            );
        }

        if (this.state.confermaFirmaUno) {
            canvas = (
                <Auxiliary className={classes.Centered}>
                    <div className={classes.DocContainer}>
                        <object data={IMAGES.doc5}  id="ricevuta_acquisto_firma_due" type="image/svg+xml" onLoad={() => this.compilaFirmaDue(this.state.ricevuta)}/>
                    </div>
                    <div className={classes.Container}>
                        <div className={classes.SigContainer} style={{border: '2px solid green'}}>
                            <SignaturePad canvasProps={{className: classes.SigPad}} ref={(ref) => {this.sigPad = ref}}/>
                            <br/>
                        </div>
                        <Button clicked={() => {this.clear();}}>CANCELLA</Button>
                        <Button clicked={() => {this.saveCanvas('firma2');}}>SALVA FIRMA</Button>
                    </div>
                </Auxiliary>
            );
        }

        if (this.state.confermaFirmaDue) {
            canvas =  <Auxiliary className={classes.Centered}>
                <div className={classes.DocContainer}>
                    <object data={IMAGES.doc6} type="image/svg+xml" id="pagina_sei"/>
                    <object data={IMAGES.doc7} type="image/svg+xml" id="pagina_sette"/>
                    <object data={IMAGES.doc8} id="scansioni" type="image/svg+xml" onLoad={() => this.inserisciScansioni()}/>
                    {this.state.ricevuta.is_coupon ? <object data={IMAGES.doc9} id="buono" type="image/svg+xml" onLoad={() => this.inserisciDatiBuono()} /> : null }
                </div>
                <div className={classes.Container}>
                    <div className={classes.SigContainer} style={{border: '2px solid green'}}>
                        <SignaturePad canvasProps={{className: classes.SigPad}} ref={(ref) => {this.sigPad = ref}}/>
                        <br/>
                    </div>
                    <Button clicked={() => {this.clear();}}>CANCELLA</Button>
                    <Button clicked={() => {this.saveCanvas('firma3');}}>SALVA FIRMA</Button>
                </div>
            </Auxiliary>
        }

        if (this.state.confermaFirmaTre && this.state.operazioniCompletate) {
            canvas = (
                <div>
                    <p>Tutte le informazioni sono state salvate</p>
                    <Button clicked={() => {this.closeWindow()}} > CHIUDI </Button>
                </div>
            )
        }

        return (
            <Auxiliary>
                {spinner}
                {photoFronteForm}
                {anteprimaFronte}
                {photoRetroForm}
                {anteprimaRetro}
                {canvas}
            </Auxiliary>
        );
    }
}

export default TakePhoto;
