import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "../../../components/UI/Button/Button";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from '@material-ui/data-grid';
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../GetDevices.css";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import instance from "../../../axios-spc";
import Modal from "../../../components/UI/Modal/Modal";
import {TextField} from "@material-ui/core";
import {MaterialReactTable} from "material-react-table";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Box from "@mui/material/Box";
import FileDownloadIcon from "@mui/material/SvgIcon/SvgIcon";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IMAGES from "../../../store/images";
import Spinner from "../../../components/UI/Spinner/Spinner";

class DevicesPending extends Component{


    state = {
        devices: [],
        bbkPackages: [],
        pageSize: 5,
        idDeviceDettaglio: null,
        visualizzaDocumento: false,
        confirmBuyBack: false,
        isRichiestaBuyBack: false,
        isValutazioneDevice: false,
        valoreDevice: 0,
        noteDevice: null,
        confirm: false,
        confirmValutazione: false,
        anchorEl: null,
        confirmValutato: false,
        deviceDaValutare: null,
        isLoading: false
    };


    componentDidMount() {
        this.getDevices();
    }

    getDevices = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
            active_status: 1
        };

        this.setState({isLoading: true});
        instance.post('/getDevicesDaValutare', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto)).toLocaleDateString();
                resp.data[i].modelloCompleto = resp.data[i].brand_name + " " + resp.data[i].model_name.replace(resp.data[i].brand_name, '') + " (" + resp.data[i].memory_gb + " GB)";

                let arrivato = (<LocalShippingIcon titleAccess={"ARRIVATO"} onClick={(id) => this.setDeviceArrivato(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let valutato = (<PriceCheckIcon titleAccess={"VALUTA"} onClick={(id) => this.openModalValutazione(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let operations = [];

                if (resp.data[i].id_stato_device == 3) operations.push(arrivato);
                if (resp.data[i].id_stato_device == 4) operations.push(valutato);
                resp.data[i].azioni = operations;
            }
            this.setState({devices: resp.data, isLoading: false})
        });
    };


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confirm: false, confirmValutazione: false});
    };

    openModalValutazione = (id, num_times) => {
        let rowToExam = this.state.devices.find((row) => row.id === id && row.num_times === num_times);
        this.setState({isValutazioneDevice: true, idDeviceDettaglio: id, deviceDaValutare: rowToExam})
    };

    handleMenu = (event) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null})
    };

    valutaDevice = (id) => {
        var postData = {
            serial_number: this.state.deviceDaValutare.serial_number,
            id_operatore: localStorage.getItem("userId"),
            num_times: this.state.deviceDaValutare.num_times,
            valore_accettazione: this.state.valoreDevice,
            note_accettazione: this.state.noteDevice,
            report_valutazione: this.state.reportValutazione
        };
        instance.post('/setStatusValutato', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmValutazione: true});
                this.ricercaCancelHandler();
                this.getDevices();
            }
        });
    };

    setDeviceArrivato(id, num_times) {
        var postData = {
            serial_number: id,
            id_operatore: localStorage.getItem("userId"),
            num_times: num_times
        };
        instance.post('/setStatusArrivato', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirm: true});
                this.getDevices();
            }
        });
    };

    inputonChangeHandler = (event, id) => {
        this.setState({valoreDevice: event.target.value});
    };
    inputonChangeNoteHandler = (event, id) => {
        this.setState({noteDevice: event.target.value});
    };
    inputonChangeReportHandler = (event, id) => {
        this.setState({reportValutazione: event.target.value});
    };
    ricercaCancelHandler = () => {
        this.setState({isValutazioneDevice: false, valoreDevice: 0, noteDevice: null, reportValutazione: null})
    };


    render() {

        let spinner = null;
        if (this.state.isLoading) {
            spinner = <Spinner />
        }

        let modal = null;

        if (this.state.isValutazioneDevice) {
            modal = (
                <Modal show={this.state.isValutazioneDevice} modalClosed={this.ricercaCancelHandler} title={"Valutazione Device"}>
                    <TextField
                        id="valutazione_device"
                        label="Inserisci valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        value={this.state.valoreDevice}
                        onChange={(e) => this.inputonChangeHandler(e, "valoreDevice")}
                    />
                    <TextField
                        id="report_valutazione_device"
                        label="Inserisci Report valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        value={this.state.reportValutazione}
                        changed={(e) => this.inputonChangeReportHandler(e, "reportValutazione")}
                    />
                    <TextField
                        id="valutazione_device"
                        label="Inserisci valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        value={this.state.noteDevice}
                        onChange={(e) => this.inputonChangeNoteHandler(e, "noteDevice")}
                    />
                    <br />
                    <Button clicked={this.valutaDevice}>Valuta Device</Button>
                </Modal>
            )
        }

        let confirmAlert = (
            <Snackbar open={this.state.confirm} closed={this.handleClose} severity="success">Stato del device cambiato in ARRIVATO A MAGAZZINO</Snackbar>
        );

        let confirmValutazione = (
            <Snackbar open={this.state.confirmValutazione} closed={this.handleClose} severity="success">Device valutato con successo!</Snackbar>
        );


        const newColumns = [
            {accessorKey: 'data_acquisto', header: 'Data Acquisto', size: 100},
            {accessorKey: 'modelloCompleto', header: 'Device'},
            {accessorKey: 'imei', header: 'IMEI'},
            {accessorKey: 'valore_acquisto', header: 'Valore', size: 75, Cell: ({ cell }) => cell.getValue() + " €"},
            {accessorKey: 'negozio_acquisto', header: 'Negozio Acquisto', size: 250},
            {accessorKey: 'stato_device', header: 'Stato'},
            {accessorKey: 'azioni', header: 'Azioni', size: 75},
        ]

        let sped = (
            <MaterialReactTable
                columns={newColumns}
                data={this.state.devices}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 50, 100]
                }}
                initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact', pagination: { pageSize: 5}  }}
                layoutMode="grid"
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <IconButton
                            onClick={(event) => this.handleMenu(event)}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={this.state.anchorEl ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={this.state.anchorEl ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}>?</Avatar>
                        </IconButton>
                        <Menu
                            anchorEl={this.state.anchorEl}
                            id="account-menu"
                            open={this.state.anchorEl}
                            onClose={() => this.handleCloseMenu()}
                            onClick={() => this.handleCloseMenu()}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                                <strong>Legenda Azioni</strong>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                                <LocalShippingIcon style={{width: '27px', cursor: 'default', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} /> Indica device arrivato
                            </MenuItem>
                            <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                                <PriceCheckIcon style={{width: '27px', cursor: 'default', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} /> Valuta device
                            </MenuItem>
                            <Divider />
                            <div style={{maxWidth: '400px', padding: '5px'}}>
                                <p>In questa tabella vengono visualizzati solamente i device in stato ACQUISTATO, SPEDITO e ARRIVATO</p>
                            </div>
                        </Menu>
                    </Box>
                )}
            />
        );

        return (
            <Auxiliary>
                {confirmAlert}
                {confirmValutazione}
                {modal}
                <Box style={{textAlign: '-webkit-center'}}>
                    <Box className={classes.ResultBox}>
                        <h1 className={classes.FiltriTitle}>
                            DEVICES IN ARRIVO
                        </h1>
                        {spinner}
                        {sped}
                    </Box>
                </Box>
            </Auxiliary>
        );
    }
}



export default (DevicesPending);
