const IMAGES = {
    doc1: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-01.svg').default,
    doc2: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-02.svg').default,
    doc3: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-03.svg').default,
    doc4: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-04.svg').default,
    doc5: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-05.svg').default,
    doc6: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-06.svg').default,
    doc7: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-07.svg').default,
    doc8: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-08.svg').default,
    doc9: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-09.svg').default,
    doc10: require('../assets/contratti/ricevuta_acquisto_smartphonecash_-10.svg').default,
    console: require('../assets/icons/icon-console.svg').default,
    pc: require('../assets/icons/icon-laptop.svg').default,
    smartphone: require('../assets/icons/icon-cell.svg').default,
    smartwatch: require('../assets/icons/icon-smartwatch.svg').default,
    docCompleto: require('../assets/contratti/ricevuta_acquisto_spc.pdf').default,
    homePage: require('../assets/home/home_smartphonecash_desktop-01.svg').default,
    logo: require('../assets/home/sc_logo_home_172x310px.png').default,
    ddt: require('../assets/ddt/ddt.svg').default,
    iconElimina: require('../assets/icons/Icon_elimina.svg').default,
    iconModifica: require('../assets/icons/Icon_modifica.svg').default,
    iconSospendi: require('../assets/icons/Icon_sospendi.svg').default,
    iconVedi: require('../assets/icons/Icon_vedi.svg').default,
    iconVediEliminato: require('../assets/icons/Icon_vedi_eliminato.svg').default,
    iconDDT: require('../assets/icons/icon_ddt.png').default,
};

export default IMAGES;
