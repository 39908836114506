import React, {Component} from "react";
import instance from "../../axios-spc";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import classes from "./Settings.css";
import Box from "@mui/material/Box";
import {Redirect} from "react-router-dom";
import Button from "../../components/UI/Button/Button"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {MaterialReactTable} from "material-react-table";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Snackbar from "../../components/UI/Snackbar/Snackbar";
import Spinner from "../../components/UI/Spinner/Spinner";


class Settings extends Component {

    state = {
        brands: [],
        modelli: [],
        campagne: [],
        devices: [],
        isGestioneDevice: false,
        isGestioneCampagne: false,
        idBrandSelezionato: null,
        idMemoriaSelezionato: null,
        idTipoDeviceSelezionato: 1,
        modello: {value: '', touched: false},
        workingPrice: {value: '', touched: false},
        memoria: [
            {key: 1, value: 1, label: '1 GB'},
            {key: 2, value: 2, label: '2 GB'},
            {key: 3, value: 3, label: '4 GB'},
            {key: 4, value: 4, label: '8 GB'},
            {key: 5, value: 5, label: '16 GB'},
            {key: 6, value: 6, label: '32 GB'},
            {key: 7, value: 7, label: '64 GB'},
            {key: 8, value: 8, label: '128 GB'},
            {key: 9, value: 9, label: '256 GB'},
            {key: 10, value: 10, label: '512 GB'},
            {key: 11, value: 11, label: '1 TB'},
            {key: 12, value: 12, label: '2 TB'},
            {key: 99, value: 99, label: 'Unique'},
        ],
        tipoDevice: [
            {key: 1, value: 1, label: 'SMARTPHONE'},
            {key: 2, value: 2, label: 'TABLET'},
            {key: 3, value: 3, label: 'SMARTWATCH'},
            {key: 4, value: 4, label: 'PC'}
        ],
        inserimentoOk: false,
        inserimentoKo: false,
        okMessage: null,
        koMessage: null,
        loading: false,

    };

    componentDidMount() {
        this.getBrands();
        this.getCampagne();
        this.getDevices();
    }

    getBrands = () => {
        var postData = {
            id_model_name: null,
            id_brand: null,
            id_memory: null
        };
        instance.post('/getPriceCheckBrands', postData).then(resp => {
            console.log(resp);
            let result = resp.data.map( el => ({key: el.id_brand, value: el.id_brand, label: el.brand_name}) );
            this.setState({brands: result})
        });
    };

    getDevices = () => {
        this.setState({loading: true})
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
        };
        instance.post('/getPriceCheckPrice', postData).then(resp => {
            console.log(resp);
            for (let i in resp.data) {
                resp.data[i].key = i;
            }
            this.setState({devices: resp.data, loading: false})
        });
    };

    getCampagne = () => {
        instance.post('/getCampagne').then(resp => {
            for (let i in resp.data) {
                resp.data[i].validita = new Date(resp.data[i].data_inizio).toLocaleDateString() + " - " + new Date(resp.data[i].data_fine).toLocaleDateString();
                resp.data[i].dati_raccolti = '';
                if (resp.data[i].imei === 1) (resp.data[i].dati_raccolti = resp.data[i].dati_raccolti + ' IMEI, ');
                if (resp.data[i].serial === 1) (resp.data[i].dati_raccolti = resp.data[i].dati_raccolti + ' SERIALE,');
                if (resp.data[i].ean === 1) (resp.data[i].dati_raccolti = resp.data[i].dati_raccolti + ' EAN ');
                let operations = [];
                let seleziona = (<AddCircleIcon color="#768828" style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} onClick={() => this.selezionaSupervalutazione(resp.data[i])}>add_circle</AddCircleIcon> )
                operations.push(seleziona);
                resp.data[i].azioni = operations;
            }
            this.setState({campagne: resp.data})
        });
    };

    onBrandsChange = (event, values) => {
        if (values) this.setState({idBrandSelezionato: values.value}); else this.setState({idBrandSelezionato: null});
    };

    onMemoryChange = (event, values) => {
        if (values) this.setState({idMemoriaSelezionato: values.value})
    };

    onTipoDeviceChange = (event, values) => {
        if (values) this.setState({idTipoDeviceSelezionato: values.value})
    };



    openOperazioniDevice = () => {
        this.getBrands();
        this.setState({isGestioneDevice: true, isGestioneCampagne: false})
    };

    openOperazioniCampagna = () => {
        this.setState({isGestioneDevice: false, isGestioneCampagne: true})
    };

    inserisciDevice = () => {

        let postData = {
            id_brand: this.state.idBrandSelezionato,
            model_name: this.state.modello.value,
            id_memory: this.state.idMemoriaSelezionato,
            working_price: this.state.workingPrice.value.replace(",","."),
            id_device_type: this.state.idTipoDeviceSelezionato
        };

        instance.post('/setPriceCheckDevice', postData).then(resp => {
            console.log(resp.data);
            if (resp.data[0].ret_code == 0) {
                const updatedFormPrice = {
                    ...this.state.workingPrice
                };
                updatedFormPrice.value = '';
                updatedFormPrice.touched = false;
                const updatedFormModel = {
                    ...this.state.modello
                };
                updatedFormModel.value = '';
                updatedFormModel.touched = false;

                this.setState({inserimentoOk: true, okMessage: resp.data[0].ret_messagge, workingPrice: updatedFormPrice, modello: updatedFormModel, idBrandSelezionato: null, idMemoriaSelezionato: null, idTipoDeviceSelezionato: 1, isGestioneDevice: false})
            } else {
                this.setState({inserimentoKo: true, koMessage: resp.data[0].ret_messagge})
            }
        });
        console.log(postData);

    };

    inputonChangeHandler = (event, id) => {
        switch (id) {
            case "modello": {
                const updatedForm = {
                    ...this.state.modello
                };
                updatedForm.value = event.target.value;
                updatedForm.touched = true;
                this.setState({modello: updatedForm});
                break;
            }
            case "workingPrice": {
                const updatedForm = {
                    ...this.state.workingPrice
                };
                updatedForm.value = event.target.value;
                updatedForm.touched = true;
                this.setState({workingPrice: updatedForm});
                break;
            }
            default: {
                const oldState = {
                    ...this.state
                };
                this.setState({state: oldState});
                break;
            }
        }

    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({inserimentoOk: false, inserimentoKo: false})
    };

    handleSaveRow = async ({ exitEditingMode, row, values }) => {
        console.log(values);
        console.log(row);
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
        this.state.devices[row.index] = values;
        //send/receive api updates here
        let postData = {
            id_brand: row.original.id_brand,
            model_name: values.model_name,
            id_memory: row.original.id_memory,
            working_price: values.working_price.replace(",","."),
            id_device_type: row.original.id_device_type
        };

        instance.post('/setPriceCheckDevice', postData).then(resp => {
            console.log(resp.data);
            if (resp.data[0].ret_code == 0) {
                this.getDevices();
                this.setState({inserimentoOk: true, okMessage: resp.data[0].ret_messagge, idBrandSelezionato: null, idMemoriaSelezionato: null, idTipoDeviceSelezionato: 1, isGestioneDevice: true})
            } else {
                this.setState({inserimentoKo: true, koMessage: resp.data[0].ret_messagge})
            }
        });
        exitEditingMode(); //required to exit editing mode
    };

    render() {

        let authRedirect = null;
        console.log('[Settings] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login"/>
        }

        let spinner = null;
        if (this.state.isLoading) {
            spinner = <Spinner />
        }

        let okSnack = (
            <Snackbar open={this.state.inserimentoOk} autoHideDuration={2000} closed={this.handleClose} severity="success">{this.state.okMessage}</Snackbar>
        );
        let koSnack = (
            <Snackbar open={this.state.inserimentoKo} autoHideDuration={2000} closed={this.handleClose} severity="error">{this.state.koMessage}</Snackbar>
        );

        let boxOperazioni = (
            <Box style={{textAlign: '-webkit-center', display: 'inline-block', width: '30%', verticalAlign: 'top'}}>
                <Box className={classes.ResultBox}>
                    <h3 className={classes.FiltriTitle}>Operazioni </h3>
                    <Button style={{margin: '10px', borderRadius: '15px'}} clicked={() => this.openOperazioniDevice()}>{('GESTIONE DISPOSITIVI')}</Button>
                    <Button style={{margin: '10px', borderRadius: '15px'}} clicked={() => this.openOperazioniCampagna()}>{('GESTIONE CAMPAGNE')}</Button>
                </Box>
            </Box>
        );

        let formTipoDevice = (
            <Autocomplete
                id="brand"
                sx={{backgroundColor: '#f4f4f4', margin: 5, borderRadius: '4px',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.tipoDevice}
                autoHighlight
                size="small"
                variant="outlined"
                onChange={this.onTipoDeviceChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Tipologia Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let formBrand = (
            <Autocomplete
                id="brand"
                sx={{backgroundColor: '#f4f4f4', width: '300px', margin: 5, borderRadius: '4px',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.brands}
                autoHighlight
                size="small"
                variant="outlined"
                onChange={this.onBrandsChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Brand Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let formModello = (
            <div style={{backgroundColor: '#f4f4f4', margin: 40, width: '300px', borderRadius: '4px'}} >
                <TextField
                    sx={{ "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: "#768828",
                            }
                    }}
                    id="modello"
                    elementType="input"
                    fullWidth
                    value={this.state.modello.value}
                    label="Modello"
                    variant="outlined"
                    // style={{ height: '40px'}}
                    onChange={(e) => this.inputonChangeHandler(e, "modello")}
                />
            </div>
        );

        let formPrezzo = (
            <div style={{backgroundColor: '#f4f4f4', margin: 40, width: '300px', borderRadius: '4px'}} >
                <TextField
                    sx={{ "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: "#768828",
                            }
                    }}
                    id="workingPrice"
                    elementType="number"
                    fullWidth
                    value={this.state.workingPrice.value}
                    label="Prezzo"
                    variant="outlined"
                    // style={{ height: '40px'}}
                    onChange={(e) => this.inputonChangeHandler(e, "workingPrice")}
                />
            </div>
        );

        let formMemoria = (
            <Autocomplete
                id="memoria"
                sx={{backgroundColor: '#f4f4f4', width: '300px', margin: 5, borderRadius: '4px',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.memoria}
                autoHighlight
                size="small"
                variant="outlined"
                onChange={this.onMemoryChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Capacità Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let boxInserimentoDevice = null;

        let buttonEnabled = this.state.idBrandSelezionato > 0 && this.state.idTipoDeviceSelezionato > 0 && this.state.idMemoriaSelezionato > 0 && this.state.modello.value !== null && this.state.modello.value !== '' && this.state.workingPrice.value !== null && this.state.workingPrice.value !== '';

        if (this.state.isGestioneDevice)
            boxInserimentoDevice = (
                <Box style={{textAlign: '-webkit-center'}}>
                    <Box className={classes.FiltriBox}>
                        <h3 className={classes.FiltriTitle}>{('Inserisci o modifica dispositivo')}</h3>
                        {formTipoDevice}
                        {formBrand}
                        {formModello}
                        {formMemoria}
                        {formPrezzo}
                        <Button style={{margin: '10px', borderRadius: '15px'}} clicked={() => this.inserisciDevice()} disabled={!buttonEnabled}>{('INSERISCI')}</Button>
                    </Box>
                </Box>
            );

        const newColumnsDevices = [
            {accessorKey: 'brand_name', header: 'Brand', editable: false, size: 100},
            {accessorKey: 'model_name', header: 'Modello', editable: false, size: 100},
            {accessorKey: 'memory', header: 'Memoria', editable: false, size: 100},
            {accessorKey: 'working_price', header: 'Valutazione', editable: false, size: 80, Cell: ({ cell }) => cell.getValue() + " €"},
        ];

        let listaDevice = null;
        if (this.state.isGestioneDevice) {
            listaDevice = (
                <div style={{width: 'fit-content'}}>
                    <MaterialReactTable
                        columns={newColumnsDevices}
                        data={this.state.devices}
                        editingMode="modal"
                        enableEditing
                        onEditingRowSave={this.handleSaveRow}
                        initialState={{ density: 'compact'}}
                    />
                </div>
            )
        }

        const newColumnsCampagne = [
            {accessorKey: 'id_campagna', header: 'Campagna', editable: false, size: 100},
            {accessorKey: 'sku', header: 'SKU', editable: false, size: 100},
            {accessorKey: 'brand_campagna', header: 'Brand', editable: false, size: 100},
            {accessorKey: 'model_name', header: 'Device ingresso', editable: false, size: 100},
            {accessorKey: 'model_name_campagna', header: 'Device supervalutazione', editable: false, size: 100},
            {accessorKey: 'supervalutazione', header: 'Valore', editable: false, size: 80, Cell: ({ cell }) => cell.getValue() + " €"},
            {accessorKey: 'dati_raccolti', header: 'Dati', editable: false },
            {accessorKey: 'validita', header: 'Validità', editable: false, size: 100},
            // {accessorKey: 'azioni', header: 'Seleziona', editable: false, size: 50},
        ];

        let boxCampagne = null;
        if (this.state.isGestioneCampagne) {
            if (this.state.campagne.length > 0)
                boxCampagne = (
                    <Box style={{textAlign: '-webkit-center'}}>
                        <Box className={classes.FiltriBox}>
                            <h3 className={classes.FiltriTitle}>{('Campagne presenti')}</h3>
                            <MaterialReactTable
                                columns={newColumnsCampagne}
                                data={this.state.campagne}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={false}
                                getRowId={(row) => row.sku}
                                initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact'}}
                            />
                        </Box>
                    </Box>
                );
        }



        // let boxCampagne = null;
        // if (this.state.campagne.length > 0)
        //     boxCampagne = (
        //         <Box style={{textAlign: '-webkit-center'}}>
        //             <Box className={classes.FiltriBox}>
        //                 <h3 className={classes.FiltriTitle}>{('Campagne presenti')}</h3>
        //                 <MaterialReactTable
        //                     columns={newColumnsCampagne}
        //                     data={this.state.campagne}
        //                     enableColumnActions={false}
        //                     enableColumnFilters={false}
        //                     enablePagination={false}
        //                     enableSorting={false}
        //                     enableBottomToolbar={false}
        //                     enableTopToolbar={false}
        //                     getRowId={(row) => row.sku}
        //                     // muiTableBodyRowProps={({ row }) => ({
        //                     //     onClick: () => this.setRowSelectionSupervalutazione(row),
        //                     //     sx: { cursor: 'pointer' },
        //                     //     selected: this.state.rowSelectionSupervalutazione === row.id
        //                     // })}
        //                     // state={ this.state.rowSelectionSupervalutazione }
        //                     // onRowSelectionChange={(e) => this.setRowSelectionSupervalutazione(e)}
        //                     initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact'}}
        //                 />
        //             </Box>
        //         </Box>
        //     );

        return (
            <Auxiliary>
                <div className={classes.Settings}>
                    {authRedirect}
                    {okSnack}
                    {koSnack}
                    <h1 className={classes.Title}>IMPOSTAZIONI</h1>
                    <div style={{height: 'auto', width: '100%', textAlign: '-webkit-center'}}>
                        {boxOperazioni}
                        {boxInserimentoDevice}
                        <br />
                        {spinner}
                        {listaDevice}
                        {boxCampagne}
                    </div>
                </div>
            </Auxiliary>
        );
    }

}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/settings"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Settings)
