import React from 'react'
import classes from './Input.css'
import { Input } from "@material-ui/core";

const input = (props) => {

    let inputElement = null;
    let validationError = null;
    const inputClasses = [classes.InputElement];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.validationError}</p>;
    }
    if (!props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Valid);
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <Input inputComponent="input" className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>;
            break;
        case ('textarea'):
            inputElement = <Input inputComponent="textarea" className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>;
            break;
        case ('select'):
            inputElement = (
                <select className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}>
                    {props.elementConfig.options.map(op => (
                        <option key={op.value} value={op.value} disabled={op.disabled}>{op.displayValue}</option>
                    ))}
                </select>);
            break;
        default:
            inputElement = <Input inputComponent="input" className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>
    }

    return (
        <div className={classes.Input}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
            {validationError}
        </div>
    )
};

export default input;
