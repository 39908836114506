import React, {Component} from "react";
import classes from './Servizi.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from "@material-ui/data-grid";
import IMAGES from '../../store/images'
import Button from '../../components/UI/Button/Button'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import instance from "../../axios-spc";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Link as RouterLink } from 'react-router-dom'
import CardActionArea from "@material-ui/core/CardActionArea";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";


class Servizi extends Component{

    componentDidMount() {
        this.props.onAuthCheckState();
    }

    state = {
        smartphoneEnabled: true,
        pcEnabled: false,
        smartWatchEnable: false,
        consoleEnabled: false
    };

    render() {

        let authRedirect = null;
        console.log('[Servizi] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        let linkDevice = '/buy-device';
        if (localStorage.getItem("role") == 3) {
            linkDevice = '/get-devices'
        }

        let comingSoon = (
            <div>
                <strong className={classes.ComingSoon}>{('COMING SOON')}</strong>
            </div>
        );
        return (
            <Auxiliary className={classes.BuyDevice}>
                <div className={classes.BuyDevice}>
                    {authRedirect}
                    <h1 className={classes.Title}> {('SERVIZI')} </h1>
                </div>
                <div>
                    <Link component={RouterLink} to={this.state.smartphoneEnabled ? linkDevice : '#'}>
                        <Card elevation={0} className={this.state.smartphoneEnabled ? classes.Active : classes.Disactive}>
                            <CardActionArea>
                                <CardMedia style={{height: '200px'}}>
                                    <img src={IMAGES.smartphone} className={this.state.smartphoneEnabled ? classes.ImgActive : classes.ImgDisactive}  />
                                </CardMedia>
                                <CardContent>
                                    <strong className={classes.UndexText}>{('SMARTPHONE')}</strong><br />
                                    <strong className={classes.UndexText}>{('TABLET')}</strong>
                                    {this.state.smartphoneEnabled ? <div>&nbsp;</div> : comingSoon}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                    <Link component={RouterLink} to={this.state.pcEnabled ? '/buy-pc' : '#'}>
                        <Card elevation={0} className={this.state.pcEnabled ? classes.Active : classes.Disactive} >
                            <CardActionArea >
                                <CardMedia style={{height: '200px'}}>
                                    <img src={IMAGES.pc} className={this.state.pcEnabled ? classes.ImgActive : classes.ImgDisactive} /></CardMedia>
                                <CardContent>
                                    <strong className={classes.UndexText}>{('PC')}</strong><br />
                                    <strong className={classes.UndexText}>&nbsp;</strong>
                                    {this.state.pcEnabled ? <div>&nbsp;</div> : comingSoon}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                    <Link component={RouterLink} to={this.state.smartWatchEnable ? '/buy-smartwatch' : '#'}>
                        <Card elevation={0} className={this.state.smartWatchEnable ? classes.Active : classes.Disactive}>
                            <CardActionArea>
                                <CardMedia style={{height: '200px'}}>
                                    <img src={IMAGES.smartwatch} className={this.state.smartWatchEnable ? classes.ImgActive : classes.ImgDisactive} />
                                </CardMedia>
                                <CardContent>
                                    <strong className={classes.UndexText}>{('SMARTWATCH')}</strong><br />
                                    <strong className={classes.UndexText}>&nbsp;</strong>
                                    {this.state.smartWatchEnable ? <div>&nbsp;</div> : comingSoon}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                    <Link component={RouterLink} to={this.state.consoleEnabled ? '/buy-console' : '#'}>
                        <Card elevation={0} className={this.state.consoleEnabled ? classes.Active : classes.Disactive}>
                            <CardActionArea>
                                <CardMedia style={{height: '200px'}}>
                                    <img src={IMAGES.console} className={this.state.consoleEnabled ? classes.ImgActive : classes.ImgDisactive}  />
                                </CardMedia>
                                <CardContent>
                                    <strong className={classes.UndexText}>{('CONSOLE')}</strong><br />
                                    <strong className={classes.UndexText}>&nbsp;</strong>
                                    {this.state.consoleEnabled ? <div>&nbsp;</div> : comingSoon}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </div>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/servizi"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Servizi);
