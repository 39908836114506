import React from 'react';
import classes from './Modal.css';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary'
import Backdrop from '../../../components/UI/Backdrop/Backdrop'
import Divider from '@mui/material/Divider';

const modalDialog = (props) => (
    <Auxiliary>
        <Backdrop show={props.show} clicked={props.modalClosed}/>
        <div className={classes.ModalDialog} style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1' : '0'
        }}>
            <h3>{props.title}</h3>
            <Divider />
            {props.children}
        </div>
    </Auxiliary>
);


export default modalDialog;

