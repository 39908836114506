import React, {Component} from "react";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import classes from "./Layout.css";
import Footer from "../../components/UI/Footer/Footer";
import {connect} from "react-redux";

class PublicLayout extends Component {

    render () {
        return (
            <div>
                <main className={classes.PublicContent}>{this.props.children}</main>
                <Footer />
            </div>
        )
    };
}


export default (PublicLayout);
