import React, {Component} from "react";
import classes from './Spedizioni.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import SpedizioniPending from "./SpedizioniPending/SpedizioniPending";

class Spedizioni extends Component{

    state = {
        reload: false
    };

    componentDidMount() {
        this.props.onAuthCheckState();
    }


    render() {

        let authRedirect = null;
        console.log('[Spedizioni] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }
        let spedizioniPending = (<SpedizioniPending />);

        return (
                <div className={classes.Spedizioni}>
                    {authRedirect}
                    <h5 className={classes.Title}>&nbsp;</h5>
                    {spedizioniPending}
                    {/*<StoricoSpedizioni />*/}
                </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/spedizioni"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Spedizioni);
