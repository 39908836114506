import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "../../../components/UI/Button/Button";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from '@material-ui/data-grid';
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../GetDevices.css";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import instance from "../../../axios-spc";
import Modal from "../../../components/UI/Modal/Modal";
import {TextField} from "@material-ui/core";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {MaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import EuroSymbolIcon from "@mui/material/SvgIcon/SvgIcon";

class DevicesLiquidati extends Component{


    state = {
        devices: [],
        bbkPackages: [],
        headers: [
            {field: 'data_acquisto', headerName: 'Data Acquisto', flex: 1},
            // {field: 'foto', headerName: 'Foto', flex: 1},
            {field: 'id', headerName: 'S.N.', flex: 1},
            {field: 'brand_name', headerName: 'Valore', flex: 1},
            {field: 'model_name', headerName: 'Modello', flex: 1},
            {field: 'memory_gb', headerName: 'Memoria', flex: 1},
            {field: 'valore_acquisto', headerName: 'Valore', flex: 1},
            {field: 'negozio_acquisto', headerName: 'Negozio Acquisto', flex: 1},
            {field: 'stato_device', headerName: 'Stato', flex: 1},
            {field: 'data_pagamento_rimborso', headerName: 'Data Rimborso', flex: 1}
        ],
        pageSize: 5,
        idDeviceDettaglio: null,
        visualizzaDocumento: false,
        confirmBuyBack: false,
        isRichiestaBuyBack: false,
        isValutazioneDevice: false,
        valoreDevice: 0,
        noteDevice: null,
        confirm: false,
        confirmValutazione: false,
        isLoading: false

    };


    componentDidMount() {
        this.getDevices();
    }

    getDevices = () => {
        this.setState({isLoading: true});
        var postData = {
            // id_negozio: localStorage.getItem("idNegozio") == 0 || localStorage.getItem() ? null : localStorage.getItem("idNegozio"),
            id_negozio: localStorage.getItem("idNegozio") == 0 || localStorage.getItem("role") == 3 ? null : localStorage.getItem("idNegozio"),
            id_stato_device: 6
        };

        instance.post('/getDevice', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto)).toLocaleDateString();
                resp.data[i].modelloCompleto = resp.data[i].brand_name + " " + resp.data[i].model_name.replace(resp.data[i].brand_name, '') + " (" + resp.data[i].memory_gb + " GB)";
                resp.data[i].data_pagamento_rimborso = new Date((resp.data[i].data_pagamento_rimborso)).toLocaleDateString();
            }
            this.setState({devices: resp.data, isLoading: false})
        });
    };

    render() {
        let spinner = null;
        if (this.state.isLoading) {
            spinner = <Spinner />
        }


        const newColumns = [
            {accessorKey: 'data_acquisto', header: 'Data Acquisto', size: 100},
            {accessorKey: 'modelloCompleto', header: 'Device'},
            {accessorKey: 'imei', header: 'IMEI'},
            {accessorKey: 'valore_acquisto', header: 'Valore', size: 75, Cell: ({ cell }) => cell.getValue() + " €"},
            {accessorKey: 'negozio_acquisto', header: 'Negozio Acquisto'},
            {accessorKey: 'data_pagamento_rimborso', header: 'Data Pagamento', Cell: ({ cell }) => cell.getValue()?.toLocaleDateString()},
            {accessorKey: 'stato_device', header: 'Stato'},
        ]


        let sped = (
            <MaterialReactTable
                columns={newColumns}
                data={this.state.devices}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 50, 100]
                }}
                initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact', pagination: { pageSize: 5}  }}
                layoutMode="grid"
            />
        );

        return (
            <Box style={{textAlign: '-webkit-center'}}>
                <Box className={classes.ResultBox}>
                    <h1 className={classes.FiltriTitle}>
                        DEVICES LIQUIDATI
                    </h1>
                    {spinner}
                    {sped}
                </Box>
            </Box>
        );
    }
}



export default (DevicesLiquidati);
