import React, {Component} from "react";
import classes from './Magazzino.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DateRangePicker } from "mui-daterange-picker";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IMAGES from "../../store/images";
import Button from '../../components/UI/Button/Button'
import Modal from "../../components/UI/Modal/Modal";
import ModalDialog from "../../components/UI/Modal/ModalDialog";
import Device from "../Magazzino/Device/Device";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import instance from "../../axios-spc";
import TextField from "../../components/UI/TextField/TextField";
import { MaterialReactTable } from 'material-react-table';
import Box from "@mui/material/Box";
import {DateRangeIcon} from "@material-ui/pickers/_shared/icons/DateRangeIcon";
import Spinner from "../../components/UI/Spinner/Spinner";
import {it} from "date-fns/locale";
import { mkConfig, generateCsv, download } from "export-to-csv";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import produce from "immer";
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';


class Magazzino extends Component {

    componentDidMount() {
        this.props.onAuthCheckState();
        this.getBrands();
        if (localStorage.getItem("role") === 2) {
            this.getDevicesMaster(this.state.filtriControls)
        } else {
            if (localStorage.getItem("role") == 3) {
                let updatedForm = {
                    ...this.state.filtriControls
                };
                const updatedFormElementStato = {
                    ...updatedForm["stato"]
                };
                let newOption = { key: 5, value: 5, displayValue: 'VALUTATO'};
                updatedFormElementStato.elementConfig.options.push(newOption);
                this.setState({filtriControls: updatedForm})
            }
            this.getDevices(this.state.filtriControls);
        }
    }

    state = {
        devices: [],
        bbkPackages: [],
        pageSize: 5,
        idDeviceDettaglio: null,
        visualizzaDocumento: false,
        confirmBuyBack: false,
        isRichiestaBuyBack: false,
        isValutazioneDevice: false,
        valoreDevice: null,
        valoreAccettazione: null,
        noteDevice: null,
        confirm: false,
        confirmValutazione: false,
        imageUno: null,
        imageDue: null,
        loadedImageUno: false,
        loadedImageDue: false,
        reportValutazione: null,
        filteredDevices: [],
        ricercaImei: '',
        filtriControls: {
            stato: {elementType: 'select', elementConfig: {options: [{key: -1, value: -1, displayValue: "Seleziona Stato", disabled: true},
                        { key: 0, value: 0, displayValue: 'TUTTI'},
                        { key: 1, value: 1, displayValue: 'ACQUISTATO'},
                        // { key: 2, value: 2, displayValue: 'DA SPEDIRE'},
                        { key: 3, value: 3, displayValue: 'SPEDITO'},
                        { key: 4, value: 4, displayValue: 'RICEVUTO SEDE'},
                        { key: 14, value: 14, displayValue: 'ANNULLATO NEGOZIO'},
                    ],  placeholder: 'Stato device'}, value: -1, style: {backgroundColor: '#f4f4f4', margin: 10, width: '300px', borderRadius: '4px'}},
            brand: {elementType: 'select', elementConfig: {options: [{key: -1, value: -1, displayValue: "Seleziona Brand", disabled: true}, { key: 0, value: 0, displayValue: 'TUTTI'}],  placeholder: 'Brand'}, value: -1, style: {backgroundColor: '#f4f4f4', margin: 10, width: '300px', borderRadius: '4px'}},
            campagna: {elementType: 'select', elementConfig: {options: [{key: -1, value: -1, displayValue: "Campagna Supervalutazione", disabled: true},
                        { key: 0, value: 0, displayValue: 'NO'},
                        { key: 1, value: 1, displayValue: 'SI'},
                    ],  placeholder: 'Campagna supervalutazione'}, value: -1, style: {backgroundColor: '#f4f4f4', margin: 10, width: '300px', borderRadius: '4px'}},
        },
        openDate: false,
        dateRange: null,
        startDate: null,
        endDate: null,
        isLoading: false,
        hover: null,
        hoverText: '',
        confirmEliminazione: null,
        isAnnullamentoDevice: false,
        deviceDaAnnullare: null,
        deviceDaValutare: null,
        isInizializzazioneDevice: false,
        confirmInizializzazione: null,
        confirmArrivato: false,
        confirmValutato: false,
        anchorEl: null,
    };


    getDevicesMaster = (payloadFiltri) => {
        this.setState({isLoading: true});
        var postData = {
            id_color: localStorage.getItem("idAffiliato") == 0 ? null : localStorage.getItem("idAffiliato"),
            active_status: payloadFiltri.stato.value === 14 || payloadFiltri.stato.value === 0 ? null : 1,
            id_negozio: null,
            id_stato_device: payloadFiltri.stato.value === -1 ? 0 : payloadFiltri.stato.value,
            data_acquisto_da: this.state.startDate === null ? null : this.state.startDate + ' 00:00',
            data_acquisto_a: this.state.endDate == null ? null : this.state.endDate + ' 23:59',
            id_brand_name: payloadFiltri.brand.value === -1 ? 0 : payloadFiltri.brand.value,
            is_supervalutazione: payloadFiltri.campagna.value
        };

        instance.post('/getDeviceMaster', postData).then(resp => {
            // axios.post('http://localhost:8989/api/getDeviceMaster', postData, axiosConfig).then(resp => {
            for (let i in resp.data) {

                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto));
                let visualizza = (<img src={IMAGES.iconVedi} title={"DETTAGLI DEVICE"} style={{width: '27px', cursor: 'pointer', padding: '2px', marginRight: '5px'}} onClick={(id) => this.handleStoricoClick(resp.data[i].id, resp.data[i].num_times)} />);
                let visualizzaEliminato = (<img src={IMAGES.iconVediEliminato} title={"DETTAGLI DEVICE"} style={{width: '27px', padding: '2px', marginRight: '5px'}} />);
                let elimina = (<DeleteForeverIcon titleAccess={"ELIMINA PERMUTA"} onClick={(id) => this.openModalAnnullamento(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: 'red', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let inizializza = (<PhonelinkEraseIcon titleAccess={"ELIMINA DATI"} onClick={(id) => this.openModalInizializzazione(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let mancaRicevuta = (<ReportProblemIcon titleAccess={"RICEVUTA INCOMPLETA"} style={{width: '27px', cursor: 'default', padding: '2px', color: resp.data[i].id_stato_device === 14 ? '#CCCCCC' : '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let operations = [];
                if (resp.data[i].id_stato_device === 14) operations.push(visualizzaEliminato); else operations.push(visualizza);
                if (resp.data[i].is_device_inizializzato === 0 && resp.data[i].id_stato_device !== 14) operations.push(inizializza);
                if (resp.data[i].documento == null && resp.data[i].id_stato_device !== 14) operations.push(mancaRicevuta);
                if (resp.data[i].id_stato_device === 1 || resp.data[i].id_stato_device === 2) operations.push(elimina);

                resp.data[i].azioni = operations;
                resp.data[i].valoreTot = resp.data[i].valore_acquisto;

                if (resp.data[i].coupon_value !== resp.data[i].valore_acquisto) resp.data[i].valoreTot = resp.data[i].valore_acquisto + " (" + resp.data[i].coupon_value + ")";
            }
            this.setState({devices: resp.data, filteredDevices: resp.data, isLoading: false})
        });
    };

    getBrands() {
        let brands = [];
        instance.post('/getBrands').then(response => {
            let brandsOptions = response.data;
            for (let key in brandsOptions) {
                brands.push({key, id: brandsOptions[key].id_brand, displayValue: brandsOptions[key].brand_name})
            }
            const brandSelect = this.generaBrandHelper("filtriControls", brands, null);
            this.setState({filtriControls: brandSelect})
        });
    }

    generaBrandHelper (formName, nazioni) {
        let nazioniOptions = nazioni.map((n, index) => {return {value: n.id, key: (index + 1), displayValue: n.displayValue, disabled: false}});
        let newOptions = {options: [{key: -1, value: -1, displayValue: "Seleziona Brand", disabled: true}, { key: 0, value: 0, displayValue: 'TUTTI'}], placeholder: 'Brand'};
        for (let obj in nazioniOptions) {
            newOptions['options'].push(nazioniOptions[obj]);
        }
        return produce(this.state[formName], draft => {
            draft.brand.elementConfig = newOptions;
        });
    }

    getDevices = (payloadFiltri) => {
        this.setState({isLoading: true});
        var postData = {
            id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
            active_status: payloadFiltri.stato.value === 14 || payloadFiltri.stato.value === 0 ? null : 1,
            id_stato_device: payloadFiltri.stato.value === -1 ? 0 : payloadFiltri.stato.value,
            data_acquisto_da: this.state.startDate === null ? null : this.state.startDate + ' 00:00',
            data_acquisto_a: this.state.endDate == null ? null : this.state.endDate + ' 23:59',
            id_brand_name: payloadFiltri.brand.value === -1 ? 0 : payloadFiltri.brand.value,
            is_supervalutazione: payloadFiltri.campagna.value
        };

        instance.post('/getDevice', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date(resp.data[i].data_acquisto);

                let visualizza = (<img src={IMAGES.iconVedi} title={"DETTAGLI DEVICE"} style={{width: '27px', cursor: 'pointer', padding: '2px', marginRight: '5px'}} onClick={(id) => this.handleStoricoClick(resp.data[i].id, resp.data[i].num_times)} />);
                let visualizzaEliminato = (<img src={IMAGES.iconVediEliminato} title={"DETTAGLI DEVICE"} style={{width: '27px', padding: '2px', marginRight: '5px'}} />);
                let elimina = (<DeleteForeverIcon titleAccess={"ELIMINA PERMUTA"} onClick={(id) => this.openModalAnnullamento(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: 'red', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let inizializza = (<PhonelinkEraseIcon titleAccess={"ELIMINA DATI"} onClick={(id) => this.openModalInizializzazione(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let mancaRicevuta = (<ReportProblemIcon titleAccess={"RICEVUTA INCOMPLETA"} style={{width: '27px', cursor: 'default', padding: '2px', color: resp.data[i].id_stato_device === 14 ? '#CCCCCC' : '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let arrivato = (<LocalShippingIcon titleAccess={"ARRIVATO"} onClick={(id) => this.setDeviceArrivato(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);
                let valutato = (<PriceCheckIcon titleAccess={"VALUTA"} onClick={(id) => this.openModalValutazione(resp.data[i].id, resp.data[i].num_times)} style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}}/>);

                let operations = [];
                if (resp.data[i].id_stato_device === 14) operations.push(visualizzaEliminato); else operations.push(visualizza);

                if (localStorage.getItem("role") < 3) {
                    if (resp.data[i].is_device_inizializzato === 0 && resp.data[i].id_stato_device !== 14) operations.push(inizializza);
                    if (resp.data[i].documento == null && resp.data[i].id_stato_device !== 14) operations.push(mancaRicevuta);
                    if (resp.data[i].id_stato_device === 1 || resp.data[i].id_stato_device === 2) operations.push(elimina);
                }

                if (localStorage.getItem("role") == 3) {
                    if (resp.data[i].id_stato_device == 3) operations.push(arrivato);
                    if (resp.data[i].id_stato_device == 4) operations.push(valutato);
                }
                resp.data[i].azioni = operations;
                resp.data[i].valoreTot = resp.data[i].valore_acquisto;
                if (localStorage.getItem("role") < 3) {
                    if (resp.data[i].coupon_value !== resp.data[i].valore_acquisto) resp.data[i].valoreTot = resp.data[i].valore_acquisto + " (" + resp.data[i].coupon_value + ")";
                }
            }
            this.setState({devices: resp.data, filteredDevices: resp.data, isLoading: false})
        });
    };

    filtraDevices() {
        let payloadFiltri = this.state.filtriControls;
        if (localStorage.getItem("role") === 2) {
            this.getDevicesMaster(payloadFiltri)
        } else {
            this.getDevices(payloadFiltri);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.visualizzaDocumento)
            console.log("[Magazzino.js] --> shouldComponentUpdate");
        return this.state !== nextState;
    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', {day: '2-digit'});
        const month = date.toLocaleString('default', {month: '2-digit'});
        const year = date.toLocaleString('default', {year: 'numeric'});
        return day + '-' + month + '-' + year;
    }

    openModalValutazione = (id, num_times) => {
        let rowToExam = this.state.devices.find((row) => row.id === id && row.num_times === num_times);
        this.setState({isValutazioneDevice: true, deviceDaValutare: rowToExam});
    };

    openModalAnnullamento = (id, num_times) => {
        let rowToExam = this.state.devices.find((row) => row.id === id && row.num_times === num_times);
        this.setState({isAnnullamentoDevice: true, deviceDaAnnullare: rowToExam})
    };

    openModalInizializzazione = (id, num_times) => {
        let rowToExam = this.state.devices.find((row) => row.id === id && row.num_times === num_times);
        this.setState({isInizializzazioneDevice: true, deviceDaAnnullare: rowToExam})
    };

    handleStoricoClick(id, num_times) {
        let rowToExam = this.state.devices.find((row) => row.id === id && row.num_times === num_times);
        this.setState({visualizzaDocumento: true, idDeviceDettaglio: rowToExam.id, deviceDaAnnullare: rowToExam});
    };

    setDeviceArrivato(id, num_times) {
        console.log("ARRIVATO " + id + " " + num_times)
        var postData = {
            serial_number: id,
            id_operatore: localStorage.getItem("userId"),
            num_times: num_times
        };
        instance.post('/setStatusArrivato', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmArrivato: true});
                this.getDevices(this.state.filtriControls);
            }
        });
    };

    handleEliminaClick(device) {
        console.log(device);
        var postData = {
            serial_number: this.state.deviceDaAnnullare.serial_number,
            id_operatore: localStorage.getItem("userId"),
            num_times: this.state.deviceDaAnnullare.num_times
        };
        instance.post('/setAnnullato', postData).then(resp => {
            // axios.post('http://localhost:8989/api/setStatusValutato', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmEliminazione: true, isAnnullamentoDevice: false});
                this.ricercaCancelHandler();
                this.getDevices(this.state.filtriControls);
            }
        });
    };

    handleInizializzaClick(device) {
        console.log(device);
        var postData = {
            serial_number: this.state.deviceDaAnnullare.serial_number,
            id_operatore: localStorage.getItem("userId"),
            num_times: this.state.deviceDaAnnullare.num_times
        };
        instance.post('/setInizializzato', postData).then(resp => {
            // axios.post('http://localhost:8989/api/setStatusValutato', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmInizializzazione: true, isInizializzazioneDevice: false})
                this.ricercaCancelHandler();
                this.getDevices(this.state.filtriControls);
            }
        });
    };

    handleOnHoverImg(e, text) {
        this.setState({hover: true, hoverText: text});
    };

    handleOnHoverImgOut(e, text) {
        this.setState({hover: false, hoverText: null});
    }

    inputChangedHandler = (event, inputIdentifier) => {

        const updatedForm = {
            ...this.state.filtriControls
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({filtriControls: updatedForm})
    };

    azzeraFiltri() {
        console.log("AZZERA");
        let updatedForm = {
            ...this.state.filtriControls
        };
        const updatedFormElementStato = {
            ...updatedForm["stato"]
        };
        updatedFormElementStato.value = -1;
        updatedForm["stato"] = updatedFormElementStato;

        const updatedFormElementCampagna = {
            ...updatedForm["campagna"]
        };
        updatedFormElementCampagna.value = -1;
        updatedForm["campagna"] = updatedFormElementCampagna;

        const updatedFormElementBrand = {
            ...updatedForm["brand"]
        };
        updatedFormElementBrand.value = -1;
        updatedForm["brand"] = updatedFormElementBrand;
        this.setState({dateRange: null, startDate: null, endDate:null, filtriControls: updatedForm});
        // this.filtraDevices();
    };

    richiediBuyBack = (text) => {
        this.setState({isRichiestaBuyBack: true, idDeviceDettaglio: text})
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({confirmBuyBack: false, confirmEliminazione: false, confirmInizializzazione: false, confirmArrivato: false, confirmValutato: false});
    };

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaDocumento: false, idDeviceDettaglio: null, isRichiestaBuyBack: false})
    };

    inputonChangeHandler = (event, id) => {
        this.setState({valoreDevice: event.target.value});
    };

    inputonChangeNoteHandler = (event, id) => {
        this.setState({noteDevice: event.target.value});
    };

    inputonChangeReportHandler = (event, id) => {
        this.setState({reportValutazione: event.target.value});
    };
    ricercaCancelHandler = () => {
        this.setState({isValutazioneDevice: false, valoreDevice: null, noteDevice: null, isAnnullamentoDevice: false, isInizializzazioneDevice: false})
    };

    valutaDevice = () => {

        var postData = {
            serial_number: this.state.deviceDaValutare.serial_number,
            id_operatore: localStorage.getItem("userId"),
            num_times: this.state.deviceDaValutare.num_times,
            valore_accettazione: this.state.valoreDevice,
            note_accettazione: this.state.noteDevice,
            report_valutazione: this.state.reportValutazione
        };
        instance.post('/setStatusValutato', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmValutato: true, deviceDaValutare: null});
                this.ricercaCancelHandler();
                this.getDevices(this.state.filtriControls);
            }
        });
    };

    liquidato = (id) => {
        var postData = {
            serial_number: id,
            id_operatore: localStorage.getItem("userId"),
            num_times: 1
        };
        instance.post('/setStatusLiquidato', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmValutazione: true});
                this.ricercaCancelHandler();
                this.getDevices(this.state.filtriControls);
            }
        });
    };

    handleFileInputOne = (e) => {
        let that = this;
        if (e.target.files[0]) {
            const formData = new FormData();
            //FILE INFO NAME WILL BE "my-image-file"
            formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(e.target.files[0]);
            }).then(function (result) {
                that.setState({imageUno: result})
            });
        }
    };

    handleFileInputTwo = (e) => {
        let that = this;
        if (e.target.files[0]) {
            const formData = new FormData();
            //FILE INFO NAME WILL BE "my-image-file"
            formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(e.target.files[0]);
            }).then(function (result) {
                that.setState({imageDue: result})
            });
        }
    };

    handleClick = (id) => {
        console.log(this.state.imageUno);
        // this.setState({firmaUno: fileFirma});
        let payloadImg = id === 1 ? this.state.imageUno : this.state.imageDue;
        let nomeFile =  id === 1 ? "reclamo_uno_" + this.state.idDeviceDettaglio : "reclamo_due_" + this.state.idDeviceDettaglio;
        let postData = {
            image: payloadImg,
            fileName: nomeFile
        } ;
        instance.post('/uploadFile', postData)
        // axios.post('http://localhost:8989/api/uploadFile', postData, axiosConfig)
            .then(response => {
                console.log("ok");
                if (id === 1) this.setState({loadedImageUno: true});
                if (id === 2) this.setState({loadedImageDue: true});
            })
            .catch(error => {
                console.log(error);
            });
    };

    handleMenu = (event) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null})
    };

    filterArray = (e) => {
        this.setState({ricercaImei: e.target.value});

        let allDevices = [...this.state.devices];
        if (e.target.value.length > 0) {
            let filtered = allDevices.filter(item => {
                return e.target.value.length > 0 ? item.imei.indexOf(e.target.value) > -1 : item;
            });
            this.setState({filteredDevices: filtered})
        } else {
            this.setState({filteredDevices: allDevices})
        }
        document.getElementById("ricercaImei").focus();
    };

    toggleDateRange() {
        let openDate = !this.state.openDate;
        this.setState({openDate: openDate})
    }

    setDateRange(dateRange) {
        let dateStart = dateRange.startDate.toLocaleString("it-IT", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        }) ;
        let dateEnd = dateRange.endDate.toLocaleString("it-IT", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });

        let startDateFilter = dateRange.startDate;
        startDateFilter.setHours(startDateFilter.getHours() + startDateFilter.getTimezoneOffset()/-60);
        let endDateFilter = dateRange.endDate;
        endDateFilter.setHours(endDateFilter.getHours() + endDateFilter.getTimezoneOffset()/-60);
        this.setState({dateRange: dateStart + " - " + dateEnd, openDate: false, startDate: startDateFilter.toJSON().slice(0, 10), endDate: endDateFilter.toJSON().slice(0, 10)})
    }

    render() {

        let authRedirect = null;
        console.log('[Magazzino] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login"/>
        }

        let spinner = null;
        if (this.state.isLoading) {
            spinner = <Spinner />
        }

        const formElementArray = [];
        for (let key in this.state.filtriControls) {
            formElementArray.push({
                id: key,
                config: this.state.filtriControls[key]
            })
        }

        let formFiltri = formElementArray.map(el => (
                <TextField
                    key={el.id}
                    label={el.config.elementConfig.placeholder}
                    variant="outlined"
                    inputProps={el.config.inputProps}
                    value={el.config.value}
                    type={el.config.elementType}
                    style={el.config.style}
                    error={!el.config.valid && el.config.touched}
                    touched={el.config.touched}
                    helperText={(!el.config.valid && el.config.touched) ? el.config.validationError.errors[el.config.errorIndex - 1] : ''}
                    options={el.config.elementConfig.options}
                    defaultValue={el.config.elementConfig.defalutValue}
                    size={"small"}
                    changed={(e, id) => this.inputChangedHandler(e, el.id)}
                />
            )
        );

        const newColumns = [
            {accessorKey: 'serial_number', header: 'ID', editable: true, },
            {accessorKey: 'brand_name', header: 'Brand', editable: true, hide: true, size: 100},
            {accessorKey: 'model_name', header: 'Modello', editable: false,  size: 110},
            {accessorKey: 'imei', header: 'IMEI', editable: false,  size: 150},
            {accessorKey: 'data_acquisto', header: 'Data Acquisto', editable: true, Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), size: 130},
            {accessorKey: 'valoreTot', header: 'Valore €', editable: true,  size: 90},
            {accessorKey: 'nome_cliente', header: 'Cliente', editable: false, size: 150},
            {accessorKey: 'stato_device', header: 'Stato', editable: false,  size: 100},
            {accessorKey: 'is_device_inizializzato', header: 'Dati Eliminati', editable: false, Cell: ({ cell }) => cell.getValue() === 0 ? (<NewReleasesIcon style={{width: '27px', cursor: 'default', padding: '2px', color: 'red', fontSize: 'xx-large',  marginRight: '5px'}} />) : (<VerifiedIcon style={{width: '27px', cursor: 'default', padding: '2px', color: 'green', fontSize: 'xx-large',  marginRight: '5px'}} />), size: 100},
            {accessorKey: 'azioni', header: 'Azioni'},
        ];

        let visibleColumns = {
            X_data_creazione: false,
            serial_number: false
        };

        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: 'Devices' });
        let csv = null;
        if (this.state.devices.length > 0) {
            let filteredDevices = this.state.devices;
            filteredDevices.forEach(item => {
                delete item.is_closed;
                delete item.is_buyback;
                delete item.is_trade_in;
                delete item.is_bollo;
                delete item.tracking_spedizione;
                delete item.valore_fee;
                delete item.id_distinta_rimborso;
                delete item.data_distinta_rimborso;
                delete item.is_rimborso_pagato;
                delete item.data_pagamento_rimborso;
                delete item.id_distinta_fee;
                delete item.data_distinta_fee;
                delete item.is_fee_pagato;
                delete item.data_pagamento_fee;
                delete item.is_bbk_possibile;
                delete item.is_bbk;
                delete item.data_bbk;
                delete item.id_operatore_bbk;
                delete item.operatore_buy_back;
                delete item.id_fattura_sede_bbk;
                delete item.id_operatore_rispedizione;
                delete item.id_operatore_accettazione;
                delete item.id_motivo_spedizione_magcent;
                delete item.report_id;
                delete item.operatore_rispedizione;
                delete item.data_rispedizione;
                delete item.image_name;
                delete item.id_causale_iva;
                delete item.causale_iva;
                delete item.documento;
                delete item.meid;
                delete item.report_valutazione;
            });
            csv = generateCsv(csvConfig)(filteredDevices);
        }

        const handleExportData = () => {
            download(csvConfig)(csv);
            console.log('export')
        };

        let menuLegenda = null;
        if (localStorage.getItem("role") < 3) {
            menuLegenda = ( <Menu
                anchorEl={this.state.anchorEl}
                id="account-menu"
                open={this.state.anchorEl}
                onClose={() => this.handleCloseMenu()}
                onClick={() => this.handleCloseMenu()}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <strong>Legenda Azioni</strong>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <img src={IMAGES.iconVedi} style={{width: '27px', cursor: 'default', padding: '2px', marginRight: '5px'}} /> Vedi dettagli dispositivo
                </MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <img src={IMAGES.iconVediEliminato} style={{width: '27px', cursor: 'default', padding: '2px', marginRight: '5px'}} /> Tasto disattivato per permute eliminate
                </MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <DeleteForeverIcon style={{width: '27px', cursor: 'default', padding: '2px', color: 'red', fontSize: 'xx-large',  marginRight: '5px'}} /> Elimina permuta
                </MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <PhonelinkEraseIcon style={{width: '27px', cursor: 'default', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} /> Elimina dati device
                </MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <ReportProblemIcon style={{width: '27px', cursor: 'default', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} /> Documenti del cliente mancanti
                </MenuItem>
            </Menu>)
        } else if (localStorage.getItem("role") == 3){
            menuLegenda = ( <Menu
                anchorEl={this.state.anchorEl}
                id="account-menu"
                open={this.state.anchorEl}
                onClose={() => this.handleCloseMenu()}
                onClick={() => this.handleCloseMenu()}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <strong>Legenda Azioni</strong>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <img src={IMAGES.iconVedi} style={{width: '27px', cursor: 'default', padding: '2px', marginRight: '5px'}} /> Vedi dettagli dispositivo
                </MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <img src={IMAGES.iconVediEliminato} style={{width: '27px', cursor: 'default', padding: '2px', marginRight: '5px'}} /> Tasto disattivato per permute eliminate
                </MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <LocalShippingIcon style={{width: '27px', cursor: 'default', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} /> Indica device arrivato
                </MenuItem>
                <MenuItem onClick={() => this.handleCloseMenu()} style={{cursor: 'default'}}>
                    <PriceCheckIcon style={{width: '27px', cursor: 'default', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} /> Valuta device
                </MenuItem>
            </Menu>)
        }

        let table = (
            <MaterialReactTable
                columns={newColumns}
                data={this.state.devices}
                enablePinning
                enableGlobalFilter={true}
                initialState={{ columnPinning: { right: ['azioni'] }, columnVisibility: visibleColumns, density: 'compact'  }}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [10, 25, 50, 100]
                }}
                layoutMode="grid"
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            clicked={() => handleExportData()}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            {'ESPORTA CSV'}
                        </Button>
                        <IconButton
                            onClick={(event) => this.handleMenu(event)}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={this.state.anchorEl ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={this.state.anchorEl ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}>?</Avatar>
                        </IconButton>
                        {menuLegenda}
                    </Box>
                )}
            />
        );

        let modal = null;
        let confirmAlert = (
            <Snackbar open={this.state.confirmBuyBack} closed={this.handleClose} severity="success">Buyback effettuato correttamente!</Snackbar>
        );
        let confirmEliminazione = (
            <Snackbar open={this.state.confirmEliminazione} closed={this.handleClose} severity="success">Ricevuta annullata con successo!</Snackbar>
        );

        let confirmInizializzazione = (
            <Snackbar open={this.state.confirmInizializzazione} closed={this.handleClose} severity="success">Dati del device eliminati con successo!</Snackbar>
        );

        let confirmArrivato = (
            <Snackbar open={this.state.confirmArrivato} closed={this.handleClose} severity="success">Stato del device cambiato in ARRIVATO A MAGAZZINO</Snackbar>
        );
        let confirmValutato = (
            <Snackbar open={this.state.confirmValutato} closed={this.handleClose} severity="success">Device valutato con successo!</Snackbar>
        );

        let docCompleto = null;
        if (this.state.visualizzaDocumento) {
            // window.open("https://collaudo.smartphonecash.it/stampaRicevuta?cod=" + this.state.idDeviceDettaglio);
            docCompleto = (
                <Modal customStyle={"Device"} show={this.state.visualizzaDocumento} modalClosed={this.visualizzaSchedaCancelHandler}
                       title={"Device " + this.state.idDeviceDettaglio}>
                    <Device id={this.state.idDeviceDettaglio} serial={this.state.deviceDaAnnullare.num_times}/>
                </Modal>
            )
        }

        let loadForm1 = (<p>Prima immagine di reclamo caricata</p>);
        let loadForm2 = (<p>Seconda immagine di reclamo caricata</p>);
        if (!this.state.loadedImageUno) {
            loadForm1 = (
                <div>
                    <Button clicked={() => this.handleClick(1)}>Carica</Button>
                    <input type="file" onChange={this.handleFileInputOne}/>
                </div>
            )
        }
        if (!this.state.loadedImageDue) {
            loadForm2 = (
                <div>
                    <Button clicked={() => this.handleClick(2)}>Carica</Button>
                    <input type="file" onChange={this.handleFileInputOne}/>
                </div>
            )
        }

        if (this.state.isValutazioneDevice) {

            modal = (
                <Modal show={this.state.isValutazioneDevice} modalClosed={this.ricercaCancelHandler}
                       title={"Valutazione Device"}>
                    <TextField
                        id="valutazione_device"
                        label="Inserisci valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        type="number"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        value={this.state.valoreDevice}
                        changed={(e) => this.inputonChangeHandler(e, "valoreDevice")}
                    />
                    <TextField
                        id="report_valutazione_device"
                        label="Inserisci Report valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        value={this.state.reportValutazione}
                        changed={(e) => this.inputonChangeReportHandler(e, "reportValutazione")}
                    />
                    <TextField
                        id="note_valutazione_device"
                        label="Inserisci Note valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        value={this.state.noteDevice}
                        changed={(e) => this.inputonChangeNoteHandler(e, "noteDevice")}
                    />
                    <br/>
                    {/*<div style={{columns: 2}}>*/}
                    {/*    {loadForm1}*/}
                    {/*    {loadForm2}*/}
                    {/*</div>*/}
                    <Button clicked={this.valutaDevice}>Valuta Device</Button>
                </Modal>
            )
        }

        if (this.state.isAnnullamentoDevice) {
            modal = (
                <ModalDialog show={this.state.isAnnullamentoDevice} modalClosed={this.ricercaCancelHandler} customStyle={"Dialog"} title={"Sicuro di confermare l'eliminazione della permuta?"}>
                    <Button style={{margin: '2%'}} clicked={(device) => this.handleEliminaClick(this.state.deviceDaAnnullare)}>Conferma</Button>
                    <Button style={{margin: '2%', backgroundColor: '#e5e5e5', color: '#235884'}} clicked={() => this.ricercaCancelHandler()}>Annulla</Button>
                </ModalDialog>)
        }

        if (this.state.isInizializzazioneDevice) {
            modal = (
                <ModalDialog show={this.state.isInizializzazioneDevice} modalClosed={this.ricercaCancelHandler} customStyle={"Dialog"} title={"Confermi di aver eseguito la cancellazione certificata dei dati presenti nel dispositivo?"}>
                    <Button style={{margin: '2%'}} clicked={(device) => this.handleInizializzaClick(this.state.deviceDaAnnullare)}>Conferma</Button>
                    <Button style={{margin: '2%', backgroundColor: '#e5e5e5', color: '#235884'}} clicked={() => this.ricercaCancelHandler()}>Annulla</Button>
                </ModalDialog>)
        }


        let testDateRange = null;
        if (this.state.openDate) {
            testDateRange = (
                <DateRangePicker
                    definedRanges={[]}
                    locale={it}
                    open={this.state.openDate}
                    toggle={() => this.toggleDateRange()}
                    onChange={(range) => this.setDateRange(range)}
                />
            )
        }

        let dateRange = (
            <Auxiliary>
                <div style={{position: 'relative', width: '320px', display: 'inline-flex'}}>
                    <TextField label="Seleziona date" type="input" style={{backgroundColor: '#f4f4f4', margin: 10, width: '300px', borderRadius: '4px'}} variant="outlined" value={this.state.dateRange != null ? this.state.dateRange : ''} clicked={() => this.toggleDateRange()} InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <DateRangeIcon />
                            </InputAdornment>
                        )
                    }}/>
                    <div style={{position: 'absolute', display: 'flex', zIndex: '2000', top: '50px'}}>
                        {testDateRange}
                    </div></div>
            </Auxiliary>
        );

        let boxFiltri = (
            <Box style={{textAlign: 'center'}}>
                <Box className={classes.FiltriBox}>
                    <h3 className={classes.FiltriTitle}>{('Filtra Dispositivi')}</h3>
                    {formFiltri}
                    {dateRange}
                    <br />
                    <Button style={{margin: '10px', borderRadius: '15px'}} clicked={() => this.filtraDevices()}>{('CERCA')}</Button>
                    <Button style={{backgroundColor: '#e5e5e5', color: '#235884', margin: '10px',borderRadius: '15px'}} clicked={() => this.azzeraFiltri()}>{('AZZERA FILTRI')}</Button>
                </Box>
            </Box>
        );

        return (
            <Auxiliary>
                {confirmAlert}
                {confirmArrivato}
                {confirmValutato}
                {confirmEliminazione}
                {confirmInizializzazione}
                {modal}
                <div className={classes.Magazzino}>
                    {authRedirect}
                    {docCompleto}
                    <h1 className={classes.Title}>MAGAZZINO</h1>
                    <div style={{height: 'auto', width: '100%'}}>
                        {boxFiltri}
                        {spinner}
                        {table}
                    </div>
                </div>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/magazzino"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Magazzino);


